import { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import classNames from "classnames";
import moment from "moment";
import CustomTable from "components/CustomTable";
import { IColumns, ISortingCriteria } from "components/CustomTable/CustomTable";
import {
  getBrandListState,
  getCampaignsListData,
  getGenreOptionsState,
  getInvitedUserState,
  getSyncedInstaMatrixState,
} from "store/selectors";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { URLRoutes } from "URLRoutes";
import Loading from "components/Loading";
import { formatNumber, formatStatusString } from "utils/utils";
import {
  requestBrandsList,
  requestCampaignsList,
  requestGenreOptions,
  requestSyncInstaMedia,
  requestUserProfile,
} from "store/rext";
import ProfileHeaderComponent from "components/ProfileHeaderComponent";
import HeaderLayout from "components/HeaderLayout";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import CustomButtonWithPopover from "components/CustomButton/CustomButtonWithPopover";
import { CAMPAIGN_COLLABORATION_STATUS, IStatus, ROLES } from "utils/Enums";
import { useUserDetail } from "HOC/useUserDetail";
import "./style.scss";

export const statusMap: { [key in CAMPAIGN_COLLABORATION_STATUS]: IStatus } = {
  [CAMPAIGN_COLLABORATION_STATUS.POST_TO_INFLUENCER]: IStatus.ACTIVE,
  [CAMPAIGN_COLLABORATION_STATUS.APPLY_CAMPAIGN]: IStatus.APPLIED,
  [CAMPAIGN_COLLABORATION_STATUS.DECLINE_CAMPAIGN]: IStatus.REJECTED,
  [CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_BRAND]: IStatus.IN_PROGRESS,
  [CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_BRAND]: IStatus.IN_PROGRESS,
  [CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_HOB]: IStatus.IN_PROGRESS,
  [CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_HOB]:
    IStatus.APPLICATION_UNSUCCESSFUL,
  [CAMPAIGN_COLLABORATION_STATUS.PENDING]: IStatus.IN_PROGRESS,
  [CAMPAIGN_COLLABORATION_STATUS.IN_PROGRESS]: IStatus.IN_PROGRESS,
  [CAMPAIGN_COLLABORATION_STATUS.APPLIED_BY_INFLUENCER]: IStatus.APPLIED_BY_INFLUENCER
};

export const statusOptions = [
  { label: "Select All", value: undefined! },
  {
    label: "Active",
    value: CAMPAIGN_COLLABORATION_STATUS.POST_TO_INFLUENCER,
  },
  {
    label: "Applied",
    value: CAMPAIGN_COLLABORATION_STATUS.APPLY_CAMPAIGN,
  },
  {
    label: "Application Unsuccessful",
    value: CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_HOB,
  },
  {
    label: "In Progress",
    value: CAMPAIGN_COLLABORATION_STATUS.IN_PROGRESS,
  },
  {
    label: "Rejected",
    value: CAMPAIGN_COLLABORATION_STATUS.DECLINE_CAMPAIGN,
  },
];

let columns: IColumns[] = [
  {
    key: "name",
    header: "Campaign Name",
    sort: true,
    sortKey: "name",
    columnClassName: "width-25",
  },
  {
    key: "brandid",
    header: "Brand",
    sortKey: "brandid",
    customValue: (data: any) => data.brand_name,
  },
  {
    key: "campaigntype",
    header: "Campaign Type",
    sort: true,
    sortKey: "campaigntype",
    customValue: (data: any) => formatStatusString(data.campaigntype),
  },
  {
    key: "enddate",
    header: "Deadline",
    sort: true,
    sortKey: "enddate",
    customValue: (data: any) =>
      data.enddate ? moment(data.enddate).format("DD-MM-YYYY") : "-",
  },
  {
    key: "golivedate",
    header: "Go-live Date",
    sort: true,
    sortKey: "golivedate",
    customValue: (data: any) =>
      data.golivedate ? moment(data.golivedate).format("DD-MM-YYYY") : "-",
  },
  {
    key: "collaboration_status",
    header: "Status",
    sortKey: "collaboration_status",
    customValue: (data: any) => (
      <StatusInfo
        campaignStatus={
          statusMap[data.collaboration_status as CAMPAIGN_COLLABORATION_STATUS]
        }
      />
    ),
  },
];

function StatusInfo({ campaignStatus, baseClassName }: any) {
  return (
    <div
      className={classNames([
        "profileCampaign-status text-bold text-align-center",
        {
          "background-green-shade4 text-green-shade2 border-1 border-green-shade-3":
            campaignStatus === IStatus.ACTIVE ||
            campaignStatus === IStatus.IN_PROGRESS,
        },
        {
          "background-orange-shade2 text-orange-shade3 border-1 border-orange-shade-3":
            campaignStatus === IStatus.LIVE ||
            campaignStatus === IStatus.APPLIED,
        },
        {
          "background-red-shade1 text-danger-color border-1 border-danger-color campaign-status-reject":
            campaignStatus === IStatus.REJECTED ||
            campaignStatus === IStatus.REJECTED_BY_HOB ||
            campaignStatus === IStatus.APPLICATION_UNSUCCESSFUL,
        },
        baseClassName || "",
      ])}
    >
      {formatStatusString(campaignStatus)}
    </div>
  );
}

function InfluencerProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instaHandle, userId, role } = useUserDetail();
  const {
    data: userData,
    fetching: userFecthing,
    message,
    error,
  } = useSelector(getInvitedUserState);
  const { data: genreOptions } = useSelector(getGenreOptionsState);
  const { data, fetching } = useSelector(getSyncedInstaMatrixState);
  const {
    data: tableData,
    fetching: tableFetching,
    error: listError,
    message: listMessage,
  } = useSelector(getCampaignsListData);

  const { data: brandsList, fetching: brandFetching , error: brandError } =
    useSelector(getBrandListState);

  const [selectedBrandFilter, setSelectedBrandFilter] = useState<
    string | undefined
  >("");
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<
    string | undefined
  >("");

  const [sortingCriteria, setSortingCriteria] = useState<ISortingCriteria>({
    sortBy: "name",
    sortOrder: "ascending",
  });
  const {
    followers_count,
    follows_count,
    media_count,
    media,
    profile_picture_url,
    avgComments,
    avgLikes,
    name,
    biography,
    reach,
  } = data || {};
 

  const transformedData = tableData.records?.map((record: any) => ({
    id: record.id,
    name: record.name,
    enddate: record.enddate,
    campaigntype: record.campaigntype,
    golivedate: record.golivedate,
    brandid: record.brandInfo.id,
    brand_name: record.brandInfo.name,
    collaboration_status: record.campaignMappingDetails?.collaboration_status,
  }));

  const handleDetailedProfileClick = () => {
    navigate(
      URLRoutes.clients.instaDashboardDetail.replace(
        ":handle",
        userData.instahandle as string
      )
    );
  };

  const handleSortChange = (criteria: ISortingCriteria) => {
    setSortingCriteria(criteria);
  };

  const handleFetchDataForSorting = (criteria: ISortingCriteria) => {
    setSortingCriteria(criteria);
    return dispatch(
      requestCampaignsList(
        role === ROLES.INFLUENCER
          ? URLRoutes.server.getCampaignsInfl
          : URLRoutes.server.getCampaignsList,
        {
          sortBy: criteria.sortBy,
          sortDirection: criteria.sortOrder,
          page: 1,
          pageSize: 100,
        }
      )
    );
  };

  const handleBrandFilterChange = useCallback(
    (value: string) => {
      setSelectedBrandFilter(value);
      setSelectedStatusFilter("");
      const filterParams = {
        page: 1,
        pageSize: 100,
      } as any;

      if (value && value.trim() !== "") {
        filterParams.brandIds = [value];
      }

      dispatch(
        requestCampaignsList(
          role === ROLES.INFLUENCER
            ? URLRoutes.server.getCampaignsInfl
            : URLRoutes.server.getCampaignsList,
          filterParams
        )
      );
    },
    [dispatch, selectedBrandFilter, role]
  );

  const handleStatusFilterChange = useCallback(
    (value: string) => {
      setSelectedStatusFilter(value);
      setSelectedBrandFilter("");
      const filterParams = {
        page: 1,
        pageSize: 100,
      } as any;

      if (value && value.trim() !== "") {
        filterParams.collaborationType = value;
      }

      dispatch(
        requestCampaignsList(
          role === ROLES.INFLUENCER
            ? URLRoutes.server.getCampaignsInfl
            : URLRoutes.server.getCampaignsList,
          filterParams
        )
      );
    },
    [dispatch, selectedStatusFilter, role]
  );

  useEffect(() => {
    dispatch(
      requestSyncInstaMedia(
        {},
        {
          instaHandle: instaHandle,
        }
      )
    );

    dispatch(
      requestCampaignsList(URLRoutes.server.getCampaignsInfl, {
        page: 1,
        pageSize: 100,
      })
    );
    dispatch(requestGenreOptions());
  }, [dispatch, instaHandle]);


  useEffect(() => {
    if (role !== ROLES.BRAND) {
      dispatch(requestBrandsList({ page: 1, pageSize: 50, userid: userId }));
    }

    dispatch(requestUserProfile({ id: userId }));
  }, [message]);


  return (
    <HeaderLayout
      title="Dashboard"
      breadcrumbs={[
        {
          text: "Dashboard",
          url: URLRoutes.clients.instaDashboard,
        },
      ]}
      contentClassName="flex flex-column flex-align-center width-100 padding-8 background-grey-shade5"
    >
      {!fetching && !userFecthing && (
        <Fragment>
          <ProfileHeaderComponent
            posts={media_count ? formatNumber(media_count) : "-"}
            profilePictureUrl={profile_picture_url}
            profileName={userData.name}
            followers={followers_count ? formatNumber(followers_count) : "-"}
            following={follows_count ? formatNumber(follows_count) : "-"}
            accountReach={formatNumber(reach) || "-"}
            city={userData.city}
            state={userData.state}
            handle={userData.instahandle}
            genreOptions={genreOptions}
            genres={userData.gener}
            bio={biography}
          />

          <CustomButtonWithPopover
            popupContent={
              <div className="padding-l-2 padding-r-2 text-3 text-bold -grey-shade-4">
                Detailed Profile
              </div>
            }
            popupBaseClass={"forward-button-popup background-grey-shade4"}
            primaryButton
            circular
            // openPopup
            iconPosition={ICON_POSITION.LEFT}
            iconProps={{
              name: CUSTOM_SVG_ICON.ArrowLeft,
              svgType: SVGType.CUSTOM,
              size: "large",
            }}
            handleClick={handleDetailedProfileClick}
            baseclassname={"forward-button"}
          />
          <div className="flex flex-row flex-justify-between flex-align-center width-100 padding-3">
            <div className="text-4 text-bold flex flex-align-start width-40">
              My Campaigns
            </div>
            <div className="flex flex-justify-end flex-align-center width-60">
              <div className="margin-r-2 text-bold ">Filters:</div>
              <Dropdown
                clearable
                placeholder="Search By Brand Name"
                inline
                selection
                scrolling
                loading={brandFetching}
                options={[
                  { key: undefined, text: "Select All", value: undefined },
                  ...(brandsList.records?.map((option: any) => ({
                    key: option.id,
                    text: option.name,
                    value: option.id,
                  })) || []),
                ]}
                onChange={(event, data) =>
                  handleBrandFilterChange(data.value as string)
                }
                value={selectedBrandFilter}
                className="margin-r-2 influencer-filters"
                disabled={!tableData.records?.length && listError && brandError}
              />
              <Dropdown
                clearable
                placeholder="Search By Status"
                inline
                selection
                scrolling
                options={statusOptions.map((option) => ({
                  key: option.value,
                  text: option.label,
                  value: option.value,
                }))}
                onChange={(event, data) =>
                  handleStatusFilterChange(data.value as string)
                }
                value={selectedStatusFilter}
                className="influencer-filters"
                disabled={!tableData.records?.length && listError && brandError}
              />
            </div>
          </div>
          <CustomTable
            columns={columns}
            tableData={transformedData || []}
            hasPagination={false}
            sortTable
            fetching={tableFetching || false}
            onSortChange={handleSortChange}
            sortingCriteria={sortingCriteria}
            contentRowClassName={"text-4"}
            tableClassName="profile-table border-1 border-grey-shade-1"
            fetchData={handleFetchDataForSorting}
            tableError={listError}
            tableMessage={listMessage}
          />
        </Fragment>
      )}
      {(fetching || userFecthing) && <Loading isGlobal />}
    </HeaderLayout>
  );
}

export default InfluencerProfile;
