import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import classNames from "classnames";
import { URLRoutes } from "URLRoutes";
import { getInvitedUserState, getMobileVerifyState } from "store/selectors";
import { deleteUser, requestUserProfile } from "store/rext";
import UsePrevious from "HOC/UsePrevious";
import { useUserDetail } from "HOC/useUserDetail";
import HeaderLayout from "components/HeaderLayout";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import Loading from "components/Loading";
import { formatNumber, getUserCategoryForCount, handleRemoveUser } from "utils/utils";
import { profileStatus, ROLES } from "utils/Enums";
import PersonalInfo from "./PersonalInfo";
import CustomButton from "components/CustomButton";
import { modalOperation } from "store/actions";
import { ModalsType } from "containers/ModalManager/ModalManager";
import "./style.scss";

enum IViews {
  Personal = "Personal",
  // Account = "Account",
  // Billing = "Billing",
  // AdditionalInfo = "Additional Info"
}

interface TitleValueProps {
  value: string;
  title: string;
  baseClassName?: string;
  isValid?: boolean; 
}

export const TitleValue = ({ value, title, baseClassName, isValid = true }: TitleValueProps) => {
  return (
    <div
      className={classNames([
        "flex flex-column width-43 margin-2",
        baseClassName || "",
      ])}
    >
      <div className="text-4 text-bold">{value || "-"}</div>

      <div className={classNames([
        "profile-title",
        {
          "text-danger-color": !isValid,  
          "text-grey-shade-2": !isValid,   
          "text-default-color": isValid    
        }
      ])}>
        {title || "-"}
      </div>
    </div>
  );
};

function Profile() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigateUrl = URLRoutes.clients.users.replace(
    ":userType",
    ROLES.INFLUENCER
  );
  const [currentView, setCurrentView] = useState<IViews>(IViews.Personal);
  const { data, fetching, message, error } = useSelector(getInvitedUserState);
  const { data: deleteData, fetching: deleteFetching, message: deleteMessage } = useSelector(getMobileVerifyState);

  const { userId, features , role: userRole } = useUserDetail();
  const { instaDetails, name, instahandle, role  } = data || {};
  const wasfecthing = UsePrevious(fetching);

  const breadcrumb = [
    {
      text: "Profile",
      url: id
        ? URLRoutes.clients.profilePage.replace(":id", id)
        : URLRoutes.clients.profile,
    },
  ];

  const breadcrumbs = id
    ? [
        {
          text: "Manage Users",
          url: navigateUrl,
        },
        ...breadcrumb,
      ]
    : breadcrumb;

  const renderView = (view: IViews) => {
    switch (view) {
      case IViews.Personal:
        return (
          <PersonalInfo
            userData={data}
            isEditable={features.getProfileFeature().editProfile}
            isEnable={role === ROLES.INFLUENCER}
          />
        );
      // case IViews.Account:
      //   return <AccountInfo />;
      // case IViews.Billing:
      //   return <BillingDetails />;
      // case IViews.AdditionalInfo:
      //   return <AdditionalInfo
      //   userData={data}
      // isEditable={features.getProfileFeature().editProfile}
      // />;
      default:
        return null;
    }
  };

  const handleRemoveAccount = () => {
    dispatch(
      modalOperation.showModal(ModalsType.AlertModal, {
        onSave: (data: any) => {
          dispatch(modalOperation.hideModal());
          dispatch(deleteUser({ userid: userId }));
          // dispatch(deleteUser({ userid: "f24a5978-b535-4b0e-b20e-b962823c15a1" }));

        },
        onClose: () => {
          dispatch(modalOperation.hideModal());
        },
        message: "Are you sure you want to remove your account? This will remove all of your information from HighOnBuzz.",
        primaryBtnTitle: "Yes",
        secondaryBtnTitle: "No",
        title: "Confirmation",
      })
    );
  }

  const handleImageError = (e: any) => {
    e.target.onerror = null;
    e.target.src = "/images/NoProfile.png";
  };

  useEffect (() => {
    if(deleteData?.hasError && deleteMessage && Object.keys(deleteData).length > 0) {
      handleRemoveUser(deleteMessage);
    } else if (!deleteData?.hasError && deleteMessage && Object.keys(deleteData).length > 0) {
      toast.info(deleteMessage)
      localStorage.clear();
      window.location.reload();
    }
  } , [deleteData])

  useEffect(() => {
    if (wasfecthing && !fetching && !error) {
      toast.success(message);
    } else if (error) {
      toast.error(message);
    }
  }, [fetching, error, message]);

  useEffect(() => {
    if (id) {
      dispatch(requestUserProfile({ id: id }));
    } else {
      dispatch(requestUserProfile({ id: userId }));
    }
  }, [message, id]);

  return (
    <HeaderLayout
      title="Profile Page"
      contentClassName="flex width-100 padding-4"
      breadcrumbs={breadcrumbs}
    >
      {role === ROLES.INFLUENCER && !fetching && (
        <div className="width-30 border-grey-shade-1 border-1 flex flex-column margin-3 height-95 overflow-auto profile-left-container">
          <div className="border-bottom border-grey-shade-1 flex flex-column flex-align-center width-100 padding-2">
            <div className="profile-image margin-b-2">
              <img
                src={
                  instaDetails?.profile_picture_url || "/images/NoProfile.png"
                }
                onError={handleImageError}
                style={{ height: "100%", width: "100%" }}
                alt="Profile"
              />
            </div>
            <div className="text-4 text-bold line-h-3 padding-b-2">
              {name || ""}
            </div>
            <div className="profile-status text-3 text-bold  line-h-3 margin-b-1">
              {getUserCategoryForCount(instaDetails?.followers_count)}
            </div>
          </div>
          <div className="width-100 padding-2">
            <div className="text-4 text-bold flex flex-justify-center width-100 padding-b-2">
              {instahandle}
              {instaDetails.profilestatus === profileStatus.COMPLETED && (
                <SvgIcon
                  name={CUSTOM_SVG_ICON.VerifiedTick}
                  svgType={SVGType.CUSTOM}
                  size={"small"}
                  baseclassname={"text-orange-shade1"}
                />
              )}
            </div>
            <div className="flex flex-wrap width-100 flex-justify-center ">
              <TitleValue
                value={formatNumber(instaDetails?.followers_count)}
                title={"Followers"}
                baseClassName={"flex-align-center"}
              />
              <TitleValue
                value={formatNumber(instaDetails?.media_count)}
                title={"Posts"}
                baseClassName={"flex-align-center"}
              />
              <TitleValue
                value={formatNumber(instaDetails?.avgcomments)}
                title={"Avg. Comments *"}
                baseClassName={"flex-align-center"}
              />
              <TitleValue
                value={formatNumber(instaDetails?.avglikes)}
                title={"Avg. Likes *"}
                baseClassName={"flex-align-center"}
              />
              <TitleValue
                value={formatNumber(instaDetails?.avgreelviews)}
                title={"Avg Reel Views *"}
                baseClassName={"flex-align-center"}
              />
              <TitleValue
                value={instaDetails?.engagementratio ? `${formatNumber(instaDetails.engagementratio)}%` : "-"}
                title={"Engagement Ratio *"}
                baseClassName={"flex-align-center"}
              />
            </div>
            <div className="last-30-days-text">* last 30 days data</div>
          </div>
        </div>
      )}
      {!fetching && (
        <div
          className={classNames([
            "margin-l-3 ",
            { "width-70 height-90": role === ROLES.INFLUENCER },
            { "width-100 height-95": role !== ROLES.INFLUENCER },
          ])}
        >
          <div className="width-80 flex flex-row">
            {Object.values(IViews).map((view) => (
              <div
                key={view}
                className={classNames(
                  [
                    "width-30 cursor-pointer flex flex-align-center flex-justify-center",
                  ],
                  { "text-grey-shade2 ": view !== currentView },
                  { "text-blue-shade1 current-info": view === currentView }
                )}
                onClick={() => setCurrentView(view)}
              >
                {view}
              </div>
            ))}
          </div>
          {renderView(currentView)}
          {userRole === ROLES.INFLUENCER && (
            <div className="flex flex-row flex-justify-end width-100">
            <CustomButton
              buttonText="Remove Account"
              handleClick={handleRemoveAccount}
              primaryButton
              circular
              textSmall
            /></div>
          )}
        </div>
      )}
      {fetching && <Loading />}
      {deleteFetching && <Loading isGlobal />}
    </HeaderLayout>
  );
}

export default Profile;
