import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "semantic-ui-react";
import { ModalHeader } from "../Common";
import {
  HookFormInput,
  HookFormRadio,
  HookFormAddressAutoComplete,
} from "components/FormInputs";
import CustomButton from "components/CustomButton";
import {
  addressTagOptions,
  getCity,
  getPostalCode,
  getState,
} from "utils/utils";
import { hookformRequired } from "utils/FormValidations";
import { useUserDetail } from "HOC/useUserDetail";
import { AddressType, CampaignType } from "utils/Enums";
import { useLocation } from "react-router-dom";

interface Props {
  onClose: () => void;
  onSave: (data: any) => void;
  title?: string;
  toggle?: any;
  campaignType: CampaignType;
}

const requiredValidation: any = {
  ...hookformRequired("Required"),
};

function ApplyCampaignModal(props: Props) {
  const location = useLocation();
  const { Addresses , instaDetails , user , userId } = useUserDetail();
  const { onClose, onSave, title, toggle, campaignType } = props;
  const [showNewAddressFields, setShowNewAddressFields] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
    reset,
  } = useForm({});
  const [selectedTag, setSelectedTag] = useState<AddressType>(
    AddressType.OTHERS
  );
  const isPublicCampaign : boolean = location.pathname.includes("ongoing-campaigns");

  const handleOnSubmit = (data: any) => {
    trigger();
    const transformData = {
      ...data,
    };
    if(isPublicCampaign) {
      transformData.userId = userId;
    }
    if (
      campaignType === CampaignType.PAID_UGC ||
      campaignType === CampaignType.BARTER_UGC
    ) {
      if (!showNewAddressFields) {
        transformData.address = {
          id: selectedAddress?.id,
          addressLine: selectedAddress?.addressLine,
          city: selectedAddress?.city,
          postalCode: selectedAddress?.postalCode,
          state: selectedAddress?.state,
          address_tag: selectedAddress?.address_tag,
        };
      }
    }
    onSave(transformData);
  };

  const updateStateAndCity = (place: any) => {
    const selectedCity = getCity(place);
    const selectedState = getState(place);
    const selectedCode = getPostalCode(place);
    setValue("address.state", selectedState);
    setValue("address.city", selectedCity);
    setValue("address.postalCode", selectedCode);
  };

  const handleInputChange = (place: any) => {
    setValue("address.addressLine", place);
  };

  const handlePlaceSelected = (place: any) => {
    updateStateAndCity(place);
  };

  const handleAddressChange = (address: any) => {
    setSelectedAddress({
      id: address.id,
      addressLine: address.address_line,
      city: address.city,
      postalCode: address.postal_code,
      state: address.state,
      address_tag: address.address_tag,
    });
  };

  const handleAddNewAddress = () => {
    setShowNewAddressFields(true);
    setSelectedAddress(undefined!);
    setValue("address", {});
  };

  const handleCancelClick = () => {
    reset();
    setShowNewAddressFields(false);
  };

  const renderShipmentAddresses = () => {
    const handleRadioChange = (e: any) => {
      setSelectedTag(e.target.value as AddressType);
    };

    const filteredAddresses = Addresses?.filter(
      (item: any) => item.address_tag === selectedTag
    );
    return (
      <div className="flex flex-row flex-wrap width-100 margin-b-2">
        {/* Render radio buttons for the address tags */}
        <div className="flex flex-row flex-wrap width-100">
          {addressTagOptions.map((option) => (
            <div
              key={option.value}
              className="text-bold margin-b-2 flex flex-align-center"
              style={{ fontSize: "0.85rem" }}
            >
              <input
                type="radio"
                id={option.value}
                name="address_tag"
                value={option.value}
                checked={selectedTag === option.value}
                onChange={handleRadioChange}
              />
              <label htmlFor={option.value} className="margin-r-3 margin-l-1">
                {option.label}
              </label>
            </div>
          ))}
        </div>

        {/* Render filtered addresses */}
        {filteredAddresses?.length > 0 ? (
          filteredAddresses.map(
            (item: any) =>
              item.state &&
              item.city && (
                <div
                  key={item.id}
                  className="flex flex-align-center width-100 cursor-pointer"
                  onClick={() => handleAddressChange(item)}
                >
                  <input
                    type="radio"
                    name="addressSelection"
                    value={item.id}
                    checked={selectedAddress?.id === item.id}
                    onChange={() => handleAddressChange(item)} // Handle radio button selection
                    className="margin-r-2"
                  />
                  <div className="text-3 text-grey-shade-2 margin-l-2">
                    {item.address_line}
                  </div>
                </div>
              )
          )
        ) : (
          <div className="text-grey-shade-2" style={{ fontSize: "0.85rem" }}>
            No addresses found for this tag.
          </div>
        )}
      </div>
    );
  };


  return (
    <Modal
      size="tiny"
      dimmer
      open
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      className="add-influencer-modal"
    >
      {title && <ModalHeader title={title} toggle={toggle} />}
      <Modal.Content>
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          className="flex flex-column width-100 height-100"
        >
          <div className="width-100 padding-2 flex flex-justify-between flex-align-center">
            <span>Quote</span>
            <HookFormInput
              name="quote"
              control={control}
              errors={errors}
              id="quote"
              placeholder="Quote"
              validation={requiredValidation}
              required
            />
          </div>
          <div className="width-100 padding-2 flex flex-justify-between flex-align-center">
            <span>Number of Retakes</span>
            <HookFormInput
              name="takes"
              control={control}
              errors={errors}
              id="takes"
              placeholder="Takes"
              validation={requiredValidation}
              required
            />
          </div>
          {(campaignType === CampaignType.BARTER_UGC ||
            campaignType === CampaignType.PAID_UGC) && (
            <div className="width-100 padding-2 flex flex-justify-between flex-align-center">
              <span className="width-30">Your Address</span>
              <div className="flex flex-column width-70">
                {showNewAddressFields ? (
                  <Fragment>
                    <HookFormRadio
                      control={control}
                      errors={errors}
                      name="address.address_tag"
                      label="Please choose Tag for address:"
                      options={addressTagOptions}
                      id="address.address_tag"
                      baseClassName="width-90 margin-t-1"
                      defaultValue={AddressType.HOME}
                    />
                    <HookFormAddressAutoComplete
                      label="Address"
                      name="address.addressLine"
                      id="address.addressLine"
                      control={control}
                      errors={errors}
                      onSelect={handlePlaceSelected}
                      handleChange={handleInputChange}
                      validation={requiredValidation}
                      required
                    />
                    {/* <HookFormInput
                    name="address.street"
                    control={control}
                    errors={errors}
                    id="address.street"
                    placeholder="Enter Street"
                    validation={requiredValidation}
                  /> */}
                    <HookFormInput
                      name="address.city"
                      control={control}
                      errors={errors}
                      id="address.city"
                      placeholder="Enter City"
                      validation={requiredValidation}
                      required
                    />
                    <HookFormInput
                      id="address.state"
                      name="address.state"
                      control={control}
                      errors={errors}
                      placeholder="Enter State"
                      validation={requiredValidation}
                      required
                    />

                    <HookFormInput
                      name="address.postalCode"
                      control={control}
                      errors={errors}
                      id="address.postalCode"
                      placeholder="Enter Postal code"
                      validation={requiredValidation}
                      required
                    />
                  </Fragment>
                ) : (
                  // <HookFormRadio
                  //   name="address"
                  //   control={control}
                  //   errors={errors}
                  //   id="address"
                  //   baseClassName="width-100 margin-r-4"
                  //   optionsClassName="flex-column"
                  //   options={Addresses?.filter((address: any) => {
                  //     return (
                  //       address.state !== null &&
                  //       address.city !== null &&
                  //       address.address_line !== null &&
                  //       address.postal_code !== null
                  //     );
                  //   }).map((address: any) => ({
                  //     value: address,
                  //     label: `${address.city}, ${address.state}, ${address.postal_code}`,
                  //   }))}
                  //   validation={requiredValidation}
                  //   required
                  //   triggerOnChange={handleAddressChange}
                  // />
                  renderShipmentAddresses()
                )}
                {!showNewAddressFields && (
                  <CustomButton
                    buttonText={"Add new Address"}
                    handleClick={handleAddNewAddress}
                    primaryButton
                    baseclassname={"cursor-pointer"}
                    textSmall
                    circular
                    // disabled={selectedAddress}
                  />
                )}
              </div>
            </div>
          )}

          <div className="flex flex-justify-end padding-r-3 border-top border-grey-shade-1 padding-t-2">
            <CustomButton
              buttonText="Cancel"
              secondaryButton
              handleClick={handleCancelClick}
              baseclassname={"cursor-pointer margin-r-4"}
              textSmall
              circular
            />

            {/* {showNewAddressFields && ( */}
            <CustomButton
              type="submit"
              buttonText={"Apply"}
              primaryButton
              baseclassname={"cursor-pointer"}
              textSmall
              circular
            />
            {/* )} */}
          </div>
        </form>
      </Modal.Content>
    </Modal>
  );
}

export default ApplyCampaignModal;
