export const server = {
  login: "/user/login",
  currentUser: "/user/current",
  googleLogin: "/user/googleLogin/:token",
  registerByEmail: "/user/register/email",
  registerByGoogle: "/user/register/google/:token",
  inviteUser: "/user/invite",
  reInvite: "/user/invite/:id",
  sendInviteToUser: "/user/sendInvite/:id",
  sendMultipleInvites: "/user/sendMultiInvite",
  changePassword: "/user/changePassword",
  userCreate: "/user/signup/:id",
  userList: "/user/list/:userType",
  userUpdate: "/user/update/:id",
  userUpload: "/user/upload",
  userForgotPassword: "/user/forgotPassword",
  userVerifyPhone: "/user/verify/phone/:id",
  verifyOtp: "/util/verifyOTP/:id",
  geners: "/util/geners",
  syncInstaData: "/insta/sync/:instaHandle",
  getInstaMatrix: "/insta/matrix/:instaHandle",
  createCampaign: "/campaign",
  updateCamapign: "/campaign/:id",
  campaignDetail: "/campaign/:id",
  getCampaignsList: "/campaign/list",
  getCampaignsInfl: "/influencers/campaigns/list",

  userDetail: "/user/invite/:id",
  upadteProfile: "/user/profile/update",
  userProfile: "/user/currentProfile/:id",
  userFcmUpdate: "/user/update/fcm",
  brandsList: "/brand/v1/list",
  brandUsersList: "/brand/v1/user/list",
  brandDetail: "/brand/v1/:id",
  createBrand: "/brand/v1/create",
  updateBrand: "/brand/v1/update/:id",
  deleteBrand: "/brand/v1/delete/:brandId",
  brandUserDetail: "/brand/v1/user/:id",
  createBrandUser: "/brand/v1/user/create",
  updateBrandUser: "/brand/v1/user/update/:id",
  deleteBrandUser: "/brand/v1/user/delete/:id",
  influencerList: "/campaign/influencers",
  influencerPost: "/campaign/map/influencers",
  getInfluencers: "/campaign/get/influencers",
  updatePrice: "/campaign/influencers/values/:id",
  postToInfluencer: "/campaign/influencers/status",
  postToBrand: "/campaign/post/brand",
  chatNotifications: "/campaign/post/chat",
  applyForCampaign: "/influencers/campaign/apply/:id",
  applyForPublicCampaign: "/influencers/campaign/applypublic/:id",
  allBrandUsersList: "/brand/v1/allUsers/list",
  campaignInfluencerDetail: "/influencers/campaign/:id",
  uploadGuideLines: "/campaign/guidelines/upload",
  guidelineDetail: "/campaign/guideline/:id",
  uploadVideo: "/influencers/video/upload",
  getVideoDetails: "/influencers/video/:id",
  deleteVideo: "/influencers/video/:id",
  videoList: "/influencers/:id/videos",
  favouritesList: "/favorite/list",
  createFavourite: "/favorite",
  markFavourite: "/favorite/mark",
  updateVideoStatus: "/campaign/video/status/:id",
  getVideo: "/influencers/s3/video/:id",
  updateShipmentStatus: "/campaign/logistics/:id",
  getAllInfluencersList: "/campaign/get/allinfluencers",

  notificationList: "/notification",
  unreadCount: "/notification/count",
  markReadNotification: "/notification/mark/read/:id",
  markAllReadNotification: "/notification/mark/all/read",

  chatNotificationList: "/notification/chat",
  chatUnreadCount: "/notification/chat/count",
  chatMarkReadNotification: "/notification/chat/mark/read/:id",
  chatMarkAllReadNotification: "/notification/chat/mark/all/read",
  chatNotificationsRead: "/notification/chat/mark/campaign",

  facebookLogin: "/user/fbLogin/:token",
  facebookSignup: "/user/register/fb/:token",
  facebookAuthentication: "/user/authentication/fb/:token",
  ageMatrix: "/insta/getagematrix/:handle",
  locationMatrix: "/insta/getlocationmatrix/:handle",
  engageMatrix: "/insta/getengagementmatrix/:handle",
  resendOTP: "/util/resendOTP/:id", 

  instaMatrixDetails: "/insta/getmatrix/:instaHandle",
  deleteUser : "/user/removeaccount",

};
