import classNames from 'classnames';
import Loading from 'components/Loading';
import React, { useMemo } from 'react';
import { CampaignCard } from './CampaignCard';
import { CAMPAIGN_COLLABORATION_STATUS, ROLES } from 'utils/Enums';
import { influencerStatusMap } from '../utils';

interface CampaignListProps {
  records: any[];
  listError: boolean;
  listFetching: boolean;
  message: string;
  error: boolean;
  listMessage: string;
  cardRefs: any;
  role: string;
  handleCardClick: (id: any) => void;
  activeCampaignId: string;
}

export const CampaignList: React.FC<CampaignListProps> = ({
  records, error , listError , listFetching , listMessage , message , cardRefs, role , handleCardClick , activeCampaignId
}) => {

    const renderCampaignList = useMemo(
        () => (item: any, index: number) => {
          const activeCampaign = (ref: any) => (cardRefs.current[index] = ref);
          return (
            <CampaignCard
              key={`Campaign-${index}`}
              activeCampaignRef={activeCampaign}
              imageSrc={item.brandInfo.logo_url || "/images/No_Image.png"}
              brandName={item.brandInfo.name || ""}
              influencerCount={item.influencersCount || "0"}
              usersCount={item.manageruserid?.length || "0"}
              budget={item.campaignMappingDetails?.budget || "0"}
              name={item?.name}
              campaignStatus={
                role === ROLES.INFLUENCER
                  ? influencerStatusMap[
                      item.campaignMappingDetails
                        ?.collaboration_status as CAMPAIGN_COLLABORATION_STATUS
                    ]
                  : item.status
              }
              handleClick={() => handleCardClick(item.id)}
              startDate={item.startdate || ""}
              endDate={item.enddate || ""}
              shortdescription={item.shortdescription || ""}
              active={item.id === activeCampaignId}
              isEnable={role !== ROLES.INFLUENCER}
            />
          );
        },
        [activeCampaignId, role]
      );

  return (
    <div
          className={classNames([
            "flex flex-column flex-align-center background-grey-shade5 campaign-left-content padding-4",
          ])}
        >
          {listFetching ? (
            <Loading isGlobal />
          ) : listError || error ? (
            <div className="text-5 text-grey-shade-4">
              {listMessage || message}
            </div>
          ) : records && records.length > 0 ? (
            records.map(renderCampaignList)
          ) : (
            listMessage
          )}
        </div>
  );
};

export default CampaignList;
