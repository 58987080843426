import { useDispatch } from 'react-redux';
import { requestCampaignInfluencerList } from 'store/rext';
import { CAMPAIGN_COLLABORATION_STATUS } from 'utils/Enums';

export const useCampaignInfluencerList = (campaignId: string, selectedFilter: boolean) => {
  const dispatch = useDispatch();

  const fetchCampaignInfluencers = () => {
    const collaborationStatus = [
      CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_BRAND,
      CAMPAIGN_COLLABORATION_STATUS.APPLY_CAMPAIGN,
      CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_BRAND,
      CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_HOB,
      CAMPAIGN_COLLABORATION_STATUS.POST_TO_INFLUENCER,
      CAMPAIGN_COLLABORATION_STATUS.PENDING,
      CAMPAIGN_COLLABORATION_STATUS.DECLINE_CAMPAIGN,
      CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_HOB,
      CAMPAIGN_COLLABORATION_STATUS.APPLIED_BY_INFLUENCER,
    ];

    // Dispatch requestGenreOptions first

    if (selectedFilter) {
      // Dispatch for selected filter
      dispatch(
        requestCampaignInfluencerList({
          page: 1,
          pageSize: 100,
          campaignId: campaignId,
          collaborationStatus: collaborationStatus,
          viewInsight: true,
        })
      );
    } else {
      // Dispatch for default case without selected filter
      dispatch(
        requestCampaignInfluencerList({
          page: 1,
          pageSize: 100,
          campaignId: campaignId,
          collaborationStatus: collaborationStatus,
        })
      );
    }
  };

  return fetchCampaignInfluencers;
};


export const useCampaignInfluencerRequest = (campaignId: string, selectedOptions: string[]) => {
    const dispatch = useDispatch();
  
    const fetchCampaignInfluencers = () => {
  
      // Check if no selected options are present
      if (!selectedOptions.length) {
        dispatch(
          requestCampaignInfluencerList({
            page: 1,
            pageSize: 10,
            campaignId: campaignId,
            collaborationStatus: [CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_HOB],
          })
        );
      } else {
        // Create the applied filters object from selectedOptions
        const appliedFilters = selectedOptions.reduce((acc: any, value) => {
          acc[value] = true;
          return acc;
        }, {});
  
        // Dispatch requestCampaignInfluencerList with the applied filters
        dispatch(
          requestCampaignInfluencerList({
            page: 1,
            pageSize: 10,
            campaignId: campaignId,
            collaborationStatus: [CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_HOB],
            ...appliedFilters,
          })
        );
      }
    };
  
    return fetchCampaignInfluencers;
  };

export enum INFLUENCER_VIDEO_STATUS {
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED",
  }