export const profileStatus = {
  COMPLETED: "COMPLETED",
  PENDING: "PENDING",
  INVITED: "INVITED",
};

export enum ROLES {
  HOB = "HOB",
  INFLUENCER = "INFLUENCER",
  BRAND = "BRAND",
}

export enum FAVROITE_ACTION_TYPE {
  MARK_FAVROITE = "MARK_FAVROITE",
  REMOVE_FAVROITE = "REMOVE_FAVROITE",
}

export enum CAMPAIGN_COLLABORATION_ACTIONS {
  POST_TO_INFLUENCER = "POST_TO_INFLUENCER",
  APPLY_CAMPAIGN = "APPLY_CAMPAIGN",
  DECLINE_CAMPAIGN = "DECLINE_CAMPAIGN",
  ACCEPT_BY_BRAND = "ACCEPT_BY_BRAND",
  DECLINE_BY_BRAND = "DECLINE_BY_BRAND",
  ACCEPT_BY_HOB = "ACCEPT_BY_HOB",
  DECLINE_BY_HOB = "DECLINE_BY_HOB",
}

export enum CAMPAIGN_COLLABORATION_STATUS {
  POST_TO_INFLUENCER = "RECEIVED",
  APPLY_CAMPAIGN = "ACCEPTED_BY_INFLUENCER",
  DECLINE_CAMPAIGN = "DECLINE_BY_INFLUENCER",
  ACCEPT_BY_BRAND = "ACCEPT_BY_BRAND",
  DECLINE_BY_BRAND = "DECLINE_BY_BRAND",
  ACCEPT_BY_HOB = "ASSIGNED",
  DECLINE_BY_HOB = "DECLINE_BY_HOB",
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  APPLIED_BY_INFLUENCER = "APPLIED_BY_INFLUENCER",
}

export enum SHIPMENT_STATUS {
  PICKUP_SCHEDULED = "PICKUP_SCHEDULED",
  PICKUP_COMPLETE = "PICKUP_COMPLETE",
  IN_TRANSIT = "IN_TRANSIT",
  OUT_FOR_DELIVERY = "OUT_FOR_DELIVERY",
  DELIVERED = "DELIVERED",
  RTO = "RTO",
  RETURN_COMPLETE = "RETURN_COMPLETE",
}

export enum PAYMENT_STATUS {
  Paid = "PAID",
  Pending = "PENDING",
  Issue = "ISSUE",
  NA = "NA",
}

export enum CampaignType {
  PAID_REPOST = "PAID_REPOST",
  PAID_UGC = "PAID_UGC",
  BARTER_REPOST = "BARTER_REPOST",
  BARTER_UGC = "BARTER_UGC",
}

export enum AddressType {
  HOME = "Home",
  OFFICE = "Office",
  WAREHOUSE = "Warehouse",
  OTHERS = "Others",
}


export enum campaignVisibility {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

export enum INFLUENCER_VIDEO_STATUS {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

export enum OTP_EVENT {
  VERIFY_EMAIL = "VERIFY_EMAIL",
  VERIFY_PHONE = "VERIFY_PHONE",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
}

export enum IStatus {
  ACTIVE = "ACTIVE",
  LIVE = "LIVE",
  INACTIVE = "IN ACTIVE",
  APPLIED = "APPLIED",
  IN_PROGRESS = "IN_PROGRESS",
  REJECTED = "REJECTED",
  REJECTED_BY_BRAND = "REJECTED_BY_BRAND",
  REJECTED_BY_HOB = "REJECTED_BY_HOB",
  APPLICATION_UNSUCCESSFUL = "APPLICATION_UNSUCCESSFUL",
  APPLIED_BY_INFLUENCER = "APPLIED_BY_INFLUENCER"
}

export enum IPROFILEVIEWS {
  Content = "Content",
  Engagement = "Engagement (Last 30 days)",
  FollowersByAge = "Followers (By Age Group)",
  FollowersByLocation = "Followers (By Location)",
}
