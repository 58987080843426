import classNames from "classnames";
import { Controller } from "react-hook-form";

interface HookFormRadioProps {
  label?: string;
  labelClassName?: string;
  baseClassName?: string;
  name: string;
  validation?: Record<string, any>;
  control: any;
  errors: any;
  inputClassName?: string;
  id: string;
  options: any;
  triggerOnChange?: (value: any) => void;
  optionsClassName?: string;
  required?: boolean;
  defaultValue?: any
}

export function HookFormRadio(props: HookFormRadioProps) {
  const {
    label,
    labelClassName,
    baseClassName,
    name,
    validation,
    control,
    errors,
    inputClassName,
    id,
    options,
    triggerOnChange,
    optionsClassName,
    required,
    defaultValue
  } = props;

  return (
    <div className={classNames(["flex flex-column", baseClassName])}>
      {control && (
        <Controller
          control={control}
          name={name}
          rules={validation || {}}
          defaultValue={defaultValue || undefined}
          render={({ field: { onChange, value, ref } }) => (
            <>
              {label && (
                <div
                  className={classNames([
                    "input-label margin-b-2",
                    labelClassName,
                  ])}
                >
                  {label}
                  { required && <span className={classNames(["position-absolute input-asterik text-danger-color "])}>&#8727;</span>}
                </div>
              )}
              <div
                className={classNames([
                  "flex flex-wrap width-100",
                  optionsClassName || "",
                ])}
              >
                {options.map((option: any, index: number) => {
                  const isChecked =
                    typeof value === "object" &&
                    typeof option.value === "object"
                      ? JSON.stringify(value) === JSON.stringify(option.value)
                      : value === option.value;

                  return (
                    <div
                      key={index}
                      className="flex flex-row flex-align-center  margin-2"
                    >
                      <input
                        type="radio"
                        className={inputClassName || ""}
                        id={`${id}-${index}`}
                        onChange={async () => {
                          onChange(option.value);
                          if (typeof triggerOnChange === "function") {
                            triggerOnChange(option.value);
                          }
                        }}
                        checked={isChecked}
                        // checked={typeof value === "object" &&
                        // typeof option.value === "object"
                        //   ? deepEqual(value, option.value)
                        //   : value === option.value}
                      />
                      <label
                        htmlFor={`${id}-${index}`}
                        className={classNames([
                          "text-3 padding-l-1 text-bold",
                          labelClassName,
                        ])}
                      >
                        {option.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        />
      )}
      {errors[name] && (
        <div className="text-3 text-danger-color padding-b-1 padding-l-1">
          {errors[name].message}
        </div>
      )}
    </div>
  );
}
