import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { CustomInput } from 'components/CustomInputs';
import { CUSTOM_SVG_ICON, SVGType } from 'components/SvgIcon';
import { SortingOptions } from '../utils';
import { ROLES } from 'utils/Enums';

interface CampaignFiltersProps {
  filters: any;
  handleFilterChange: (name: string, value: string) => void;
  handleSortingFilter: (value: SortingOptions) => void;
  sortingFilterOptions: any;
  brandFilterOptions: any;
  role: string;
  campaignDetail: any;
  handleBrandFilterChange : (value: string) => void;
  sortingDisabled: boolean;
}

export const CampaignFilters: React.FC<CampaignFiltersProps> = ({
  filters,
  handleFilterChange,
  handleSortingFilter,
  sortingFilterOptions,
  brandFilterOptions,
  role,
  campaignDetail,
  handleBrandFilterChange,
  sortingDisabled
}) => (
    <div className="width-100 border-bottom border-grey-shade-1 padding-4 flex flex-justify-between flex-align-center campaign-left-header">
    <CustomInput
      id="filter"
      placeholder="Search for Campaigns, Brands..."
      baseClassName="width-50 filter-input"
      iconProps={{
        name: CUSTOM_SVG_ICON.Search,
        svgType: SVGType.CUSTOM,
        size: "small",
      }}
      iconRight
      name="filter"
      initialValue={filters.search}
      onChange={handleFilterChange}
      disabled={!campaignDetail.id}
    />
    <div className="flex width-50 flex-justify-end ">
      <Dropdown
        placeholder="Sort"
        inline
        options={sortingFilterOptions}
        onChange={(event, data) =>
          handleSortingFilter(data.value as SortingOptions)
        }
        value={filters.sorting}
        className="margin-r-8"
        disabled={sortingDisabled}
      />
      {role !== ROLES.BRAND && (
        <Dropdown
          placeholder="Filter By Brand"
          inline
          scrolling
          options={brandFilterOptions}
          onChange={(event, data) =>
            handleBrandFilterChange(data.value as string)
          }
          value={filters.brand}
          disabled={sortingDisabled}
        />
      )}
    </div>
  </div>
);

export default CampaignFilters;
