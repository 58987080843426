import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { URLRoutes } from "URLRoutes";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import AuthLayout from "components/AuthLayout";
import {
  modalOperation,
  requestSignupWithFacebook,
  requestSignupWithGoogle,
} from "store/actions";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth, facebookProvider, googleProvider } from "firebaseConfig";
import { getAuthState } from "store/selectors";
import { handleTermsModal } from "utils/utils";
import "./style.scss";
import Loading from "components/Loading";

enum signupTypes {
  GOOGLE = "google",
  FACEBOOK = "facebook",
  EMAIL = "email",
}

function SignUp() {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, message, fetching, error , emailVerified } = useSelector(getAuthState);
  const [enableNavigate, setEnableNavigate] = useState<boolean>(false);
  const [activeSignupType, setActiveSignupType] = useState<signupTypes | null>(
    null
  );

  const handleEmailClick = () => {
    setActiveSignupType(signupTypes.EMAIL);
    setEnableNavigate(true);
    if (id) {
      navigate(URLRoutes.clients.signUpEmailWithId.replace(":id", id));
    } else {
      navigate(URLRoutes.clients.signUpEmail);
    }
  };

  const handleGoogleSignUp = () => {
    googleProvider.setCustomParameters({
      prompt: "consent",
    });
    signInWithPopup(auth, googleProvider)
      .then(async (result) => {
        try {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential?.idToken;
          if (id) {
            dispatch(requestSignupWithGoogle({ token, id }));
          } else {
            dispatch(requestSignupWithGoogle({ token: token }));
          }
          setActiveSignupType(signupTypes.GOOGLE);
          setEnableNavigate(true);
        } catch (error: any) {
          toast.error(error);
          setActiveSignupType(null);
        }
      })
      .catch((error) => {
        setActiveSignupType(null);

        if (error.code === "auth/account-exists-with-different-credential") {
          toast.error(
            "User already exist with this google account email. Kindly try signup using different credentials!",
            { autoClose: 20000 }
          );
        } else if (error.code === "auth/cancelled-popup-request") {
          // toast.error("Popup closed by the user");
          console.error("Popup closed by the user");
        }
      });
  };


  const handleFacebookSignUp = () => {
    // setEnableNavigate(false);
    facebookProvider.setCustomParameters({
      config_id: "1373830100684881",
      response_type: "code",
      display: "popup",
    });
    signInWithPopup(auth, facebookProvider)
      .then(async (result) => {
        try {
          const credential = FacebookAuthProvider.credentialFromResult(result);
          const token = credential?.accessToken;
          if (id) {
            dispatch(requestSignupWithFacebook({ token, id }));
          } else {
            dispatch(requestSignupWithFacebook({ token: token }));
          }
          setActiveSignupType(signupTypes.FACEBOOK);
          setEnableNavigate(true);
        } catch (error: any) {
          toast.error(error);
          setActiveSignupType(null);
        }
      })
      .catch((error) => {
        setActiveSignupType(null);
        if (error.code === "auth/account-exists-with-different-credential") {
          toast.error(
            "User already exist with this facebook account email. Kindly try signup using different credentials!",
            { autoClose: 20000 }
          );
        } else if (error.code === "auth/cancelled-popup-request") {
          console.error("Popup closed by the user");
        }
      });
  };  
  
  useEffect(() => {
    if (activeSignupType === signupTypes.FACEBOOK && enableNavigate && !fetching && !error) {
      if(emailVerified) {
        toast.success(message);
      } else {
        toast.info("No Instagram business account associated with this Facebook account. Connect atleast one Instagram Business account and try authentication again or try with another Facebook account", {
          position: "top-right",
          autoClose: 30000,
          style: {
            position: "absolute",
            maxWidth: '500px', 
            right: 0,       
            margin: '0',   
          }
        });      }
    } else if (error) {
      toast.error(message);
    }
  }, [fetching, error, message, emailVerified]);
  
  useEffect(() => {
    if (emailVerified && user && user?.id) {
          navigate(URLRoutes.clients.mobileVerify.replace(":id", user.id));
        setEnableNavigate(false);
    }
  }, [emailVerified]);

  useEffect(() => {
    if (enableNavigate) {
      if (!fetching && !error && activeSignupType !== signupTypes.FACEBOOK) {
        toast.success(message);
        if (user && user?.id) {
          navigate(URLRoutes.clients.mobileVerify.replace(":id", user.id));
        }
        setEnableNavigate(false);
      } else if (error && activeSignupType !== signupTypes.FACEBOOK) {
        toast.error(message);
        setEnableNavigate(false);
      }
    }
  }, [fetching, error, message, enableNavigate]);

  useEffect(() => {
    handleTermsModal();
  }, []);

  return (
    <AuthLayout
      image="/images/womanSunglasses.png"
      title="Discover the world’s top Influencers."
      titlePosition="top"
      contentClassName="flex-justify-center flex-align-center"
      bannerClassName="background-linear1"
      imagePosition="right"
      isLoginPage
    >
      <div className="width-45 padding-b-8">
        <img src="images/logo.png" style={{ width: "100%", height: "100%" }} />
      </div>
      <div className="text-4 text-bold padding-b-4"> Sign-up with</div>
      <div
        className="signup-google margin-b-6 position-relative"
        onClick={handleGoogleSignUp}
      >
        <SvgIcon
          svgType={SVGType.CUSTOM}
          name={CUSTOM_SVG_ICON.Google}
          size={"large"}
          baseclassname={"padding-r-3"}
        />
        {activeSignupType === signupTypes.GOOGLE && fetching ? (
          <Loading />
        ) : (
          <div>GOOGLE</div>
        )}
      </div>
      <div className="border-bottom border-grey-shade-1 width-30 margin-b-6 position-relative">
        <span className="middle-text">or</span>
      </div>
      <div
        className="signup-facebook margin-b-6 position-relative"
        onClick={handleFacebookSignUp}
      >
        <SvgIcon
          svgType={SVGType.CUSTOM}
          name={CUSTOM_SVG_ICON.facebook}
          size={"large"}
          baseclassname={"padding-r-3"}
        />
        {activeSignupType === signupTypes.FACEBOOK && fetching ? (
          <Loading />
        ) : (
          <div>FACEBOOK</div>
        )}
      </div>
      <div className="border-bottom border-grey-shade-1 width-30 margin-b-6 position-relative">
        <span className="middle-text">or</span>
      </div>
      <div
        className="signup-email margin-b-6 padding-3 position-relative"
        onClick={handleEmailClick}
      >
        <SvgIcon
          svgType={SVGType.SEMANTIC}
          name={"mail"}
          baseclassname={"text-default-color padding-r-6"}
        />
        {activeSignupType === signupTypes.EMAIL && fetching ? (
          <Loading />
        ) : (
          <div>EMAIL</div>
        )}
      </div>
      <div className="text-3 text-align-center padding-b-4 width-50">
        By creating an account you agree with our
        <div
          className="text-blue-color text-underline margin-l-1 margin-r-1 cursor-pointer"
          onClick={handleTermsModal}
        >
          Terms of Service & Privacy Policy
        </div>
        and our default Notification Settings.
      </div>
      <div className="text-3">
        Already have an account?
        <Link
          to={URLRoutes.clients.login}
          className="text-default-color margin-l-1"
        >
          Sign In
        </Link>
      </div>
    </AuthLayout>
  );
}

export default SignUp;
