import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, Modal, Popup } from "semantic-ui-react";
import CustomButton from "components/CustomButton";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllInfluencersListState,
  getFavouriteListState,
} from "store/selectors";
import {
  postInfluencersToCampaign,
  requestAllCampaignInfluencers,
  requestFavouritesList,
  requestGenreOptions,
} from "store/rext";
import { getGenreOptionsState } from "store/selectors";
import classNames from "classnames";
import {
  NewGenderOptions,
  findLabelById,
  followersOptions,
  formatNumber,
  getUserCategoryForCount,
} from "utils/utils";
import Loading from "components/Loading";
import { FollowersDropdown, CustomInput } from "components/CustomInputs";
import FavouritesOverlay from "components/FavouritesOverlay";
import CustomTable from "components/CustomTable";
import { toast } from "react-toastify";
import "./style.scss";
import TooltipText from "components/TooltipText";

interface Props {
  onClose: () => void;
  onSave: () => void;
  title?: string;
  toggle?: any;
  campaignId: string;
  categories : Array<string>;
}

interface IFilters {
  location?: string;
  gender?: string;
  geners?: string;
  search?: string;
  favouriteId?: string;
  isRegistered?: boolean;
  followers?: string;
  minFollowers?: number;
  maxFollowers?: number;
}


function AddInfluencerModal(props: Props) {
  const { onClose, onSave, campaignId , categories } = props;
  const dispatch = useDispatch();
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const {
    data,
    fetching,
    message: influencerMessage,
    error,
  } = useSelector(getAllInfluencersListState);
  const { records , paginationInfo } = data || {};
  const [selectedAlphabet, setSelectedAlphabet] = useState<string>("ALL");
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [filters, setFilters] = useState<IFilters>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data: genreOptions } = useSelector(getGenreOptionsState);
  const { data: favouriteData } = useSelector(getFavouriteListState);

  const getFilteredOptions = () => {
    const filteredOptions = followersOptions.filter(option =>
      option.value === "custom" || categories.includes(option.value)
    );

  return filteredOptions;
};

const columns: any[] = [
    {
      key: "checkbox",
      header: "",
      columnClassName: "width-5",
      customValue: (data: any) => (
        <div
          className="flex flex-row flex-align-center flex-justify-center"
        >
          <input
            type="checkbox"
            checked={selectedUserIds.includes(data.id)}
            onChange={() => handleCheckboxChange(data.id)}
          />
        </div>
      ),
    },
    {
      key: "name",
      header: "Instagram Profiles",
      sort: true,
      sortKey: "name",
      columnClassName:"width-18",
      customValue: (data: any) => (
        <div
          className="flex flex-row flex-align-center width-100"
        >
          <div className="profile-detail-img text-2">
            <img
              src={data.profile_picture_url || "/images/NoProfile.png"}
              alt="Profile"
              onError={handleImageError}
            />
          </div>
          <div
            className="flex flex-column flex-justify-center  cursor-pointer"
            // onClick={() => handleInfluencerDetail(data.instahandle , data.userid)}
          >
            <div className="profile-detail-text1 flex">
              {data.name}
              {data.profilestatus && data.profilestatus === "COMPLETED" && (
                <SvgIcon
                  name={CUSTOM_SVG_ICON.VerifiedTick}
                  svgType={SVGType.CUSTOM}
                  size={"small"}
                  baseclassname={"text-orange-shade1 margin-l-2"}
                />
              )}
            </div>
            <div className="profile-detail-text2 flex margin-t-1">{data.instahandle}</div>
          </div>
        </div>
      ),
    },
    {
      key: "followers_count",
      header: "Followers",
      sortKey: "followers_count",
      customValue: (data: any) =>
        data.followers_count ? formatNumber(data.followers_count) : "-",
    },
    {
      key: "avglikes",
      header: "Avg. Likes *",
      sortKey: "avglikes",
      customValue: (data: any) =>
        data.avglikes ? formatNumber(data.avglikes) : "-",
    },
    {
      key: "avgviews",
      header: "Avg. Reel Views *",
      sortKey: "avgviews",
      customValue: (data: any) =>
        data.avgreelviews ? formatNumber(data.avgreelviews) : "-",
    },
    {
      key: "engagementratio",
      header: "ER *",
      sortKey: "engagementratio",
      columnClassName: "width-10",
      customValue: (data: any) => data.engagementratio ? `${formatNumber(data.engagementratio)}%` : "-",
    },
    {
      key: "state",
      header: "Location",
      sortKey: "state",
      columnClassName: "width-12 influencer-location",
      customValue: (data: any) => {
        const city = data.city || '';
        const state = data.state || '';
        
        // Check if both city and state are empty or undefined
        if (!city && !state) {
          return <TooltipText value="-" />;
        }

        // Otherwise, show city and state with a comma
        return <TooltipText baseClassName="tooltip-text-normal" value={`${city}${city && state ? ', ' : ''}${state}`} />;
      },
    },
    {
      key: "gener",
      header: "Categories",
      sortKey: "gener",
      columnClassName: "width-25 discovery-geners",
      customValue: (data: any) => renderGenres(data.gener) || "-"
    },
  ];

  const renderGenres = (genres: any) => {
    if (!genres || genres.length === 0) return null;

    const visibleGenres = genres.slice(0, 2);
    const hiddenGenres = genres.slice(2);

    return (
      <div className="flex flex-wrap">
        {visibleGenres.map((genre: string, index:number) => (
          <div
            key={`genre${index}`}
            className="flex flex-align-center text-align-center text-grey-shade-4 border-1 border-grey-shade-1 margin-1 influencer-genres"
          >
            {findLabelById(genreOptions , genre)}
          </div>
        ))}
        {hiddenGenres.length > 0 && (
          <Popup
            content={(<div className="width-95 flex flex-wrap flex-justify-center">{hiddenGenres.map((genre:string) =><div className="flex flex-align-center flex-justify-center text-3  text-grey-shade-4 border-1 border-grey-shade-1 margin-1 profile-genres profile-genres-hidden text-align-center">{findLabelById(genreOptions , genre)}</div>)}</div>)}
            trigger={<div className=" text-grey-shade-4 border-1 border-grey-shade-1  margin-1 influencer-genres">+{hiddenGenres.length}</div>}
            position="bottom center"
          />
        )}
      </div>
    );
  };

  
  const handleImageError = (e: any) => {
    e.target.onerror = null;
    e.target.src = "/images/NoProfile.png";
  };
  const renderAlphabets = () => {
    const alphabets = [
      "ALL",
      ...[...new Array(26)].map((_, i) => String.fromCharCode(65 + i)),
    ];
    return alphabets.map((alphabet, index) => (
      <span
        className={classNames([
          `cursor-pointer padding-1`,
          { "active-alphabet": alphabet === selectedAlphabet },
        ])}
        key={index}
        onClick={() => handleAlphabetClick(alphabet)}
      >
        {alphabet}
      </span>
    ));
  };

  const fetchInfluencers = (filterParams: IFilters = {} , alphabet?: string , page?: number) => {
    const filteredParams = Object.entries(filterParams).reduce((acc: any, [key, value]) => {
      if (value !== undefined && value !== "") {
        acc[key] = value;
      }
      return acc;
    }, {});

    const params = {
      page: page ? page : currentPage,
      pageSize: 100,
      sortByAlphabet: alphabet ? alphabet : selectedAlphabet,
      campaignId,
      ...filteredParams
    };
  
    dispatch(requestAllCampaignInfluencers(params));
  };

  const handleAlphabetClick = (alphabet: string) => {
    setSelectedAlphabet(alphabet);
    setCurrentPage(1);
    fetchInfluencers(filters , alphabet , 1)
  };
  
  const handleFilterChange = (name: string, value: any) => {
    setFilters((prevFilters) => {
      const updatedFilters : any = {
        ...prevFilters,
        [name]: value,
      };

      fetchInfluencers(updatedFilters , "ALL");
      return updatedFilters;
    });
    setCurrentPage(1);
    setSelectedAlphabet("ALL");
  };

  const handleCheckboxChange = (id: string) => {
    const isSelected = selectedUserIds.includes(id);
    if (isSelected) {
      setSelectedUserIds((prevIds) =>
        prevIds.filter((userId) => userId !== id)
      );
    } else {
      setSelectedUserIds((prevIds) => [...prevIds, id]);
    }
  };

  const handleAddInfluencerClick = () => {
    if (campaignId) {
      dispatch(
        postInfluencersToCampaign({
          campaignId: campaignId,
          influencerIds: selectedUserIds,
        })
      );
    }
    onSave();
  };

  const handleChange = (
    value: string,
    customValues?: { min?: number; max?: number }
  ) => {
    const updatedFilters = {
      ...filters,
      followers: value,
      minFollowers: value === "custom" ? customValues?.min : undefined,
      maxFollowers: value === "custom" ? customValues?.max : undefined,
    };
  
    if (value !== "custom") {
      updatedFilters.followers = value
    }
  
    // Validate custom values
    if (value === "custom" && customValues) {
      if (customValues.max && customValues.min && customValues.max <= customValues.min) {
        toast.error("Max Followers should be greater than Min Followers");
        return;
      }
    }
    setCurrentPage(1);
    setSelectedAlphabet("ALL");
    setFilters(updatedFilters);
  
    // Prepare params for the API call
    const apiParams = {
      page: currentPage,
      pageSize: 100,
      campaignId: campaignId,
      sortByAlphabet: "ALL",
      ...Object.fromEntries(
        Object.entries(updatedFilters).filter(([_, value]) => value !== undefined && value !== "")
      ),
    };
  
    // Dispatch the API call
    dispatch(requestAllCampaignInfluencers(apiParams));
  };
  
  const handleClearAllFilters = () => {
    setFilters({
      location: "",
      gender: "",
      geners: "",
      search: "",
      favouriteId: "",
      isRegistered: false,
      followers:"",
      minFollowers: undefined,
      maxFollowers: undefined,
    });
    setSelectedAlphabet("ALL");

    fetchInfluencers({} , "ALL" , 1);
  };

  const handlePagination = (e: any, { activePage }: any) => {
    setCurrentPage(activePage);
    dispatch(
      requestAllCampaignInfluencers({
        page: activePage,
        pageSize: 100,
        campaignId: campaignId,
        sortByAlphabet: selectedAlphabet ,
      })
    );
  };

  const handleButtonClick = () => {
    setShowOverlay(true);
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  useEffect(() => {
    if (genreOptions && genreOptions.length > 0) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        geners: genreOptions[0].value,
      }));
    }
  }, [genreOptions]);

  useEffect(() => {
    fetchInfluencers(filters)

    dispatch(
      requestFavouritesList({
        page: currentPage,
        pageSize: 10,
      })
    );
    dispatch(requestGenreOptions());
  }, [dispatch, campaignId,]);

  return (
    <Modal
      size="fullscreen"
      dimmer
      open
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      className="add-influencer-modal"
    >
      <Modal.Content>
        <div className="flex flex-column width-100 height-15 flex-justify-between flex-align-center margin-b-4">
          <div className="text-blue-shade-2  text-6 padding-b-1 text-bold">
            Select Influencer
          </div>

          <div className="text-grey-shade-4 text-3 line-h-3 padding-b-2">
            Choose an influencer from the below list or utilize the search field
            to find them by name.
          </div>
          <CustomInput
            baseClassName="width-30"
            onChange={handleFilterChange}
            placeholder="Search through Influencer Name"
            id="search"
            name="search"
            initialValue={filters.search}
          />
          <CustomButton
            buttonText="Favourite Lists"
            primaryButton
            textSmall
            circular
            baseclassname={"fav-button cursor-pointer "}
            handleClick={handleButtonClick}
          />

          <CustomButton
            handleClick={onClose}
            greyButton
            transparent
            iconPosition={ICON_POSITION.LEFT}
            iconProps={{
              svgType: SVGType.CUSTOM,
              name: CUSTOM_SVG_ICON.Cross,
              size: "huge",
            }}
            noOutline
            baseclassname={"add-influencer-modal-toggle-icon"}
          />
        </div>
        <div className="flex flex-column width-100 height-85">
          <div className="flex flex-row flex-justify-between width-100 border-bottom border-grey-shade-1 border-top padding-3 text-3 flex-align-center">
            <div className="text-3 flex flex-align-center width-20">
              <SvgIcon
                name={CUSTOM_SVG_ICON.VerifiedTick}
                svgType={SVGType.CUSTOM}
                size={"small"}
                baseclassname={"text-orange-shade1"}
              />
              <div className="margin-t-1 margin-l-1">Registered Influencer</div>
              <Checkbox
                toggle
                className="margin-l-2 margin-r-1"
                checked={filters.isRegistered}
                onChange={(e, data) =>
                  handleFilterChange("isRegistered", data.checked as boolean)
                }
              />
            </div>
            <div className="flex flex-row flex-align-center flex-justify-between width-75">
              <FollowersDropdown
                placeholder="Select Followers"
                onChange={handleChange}
                value={filters.followers as string}
                // options={getFilteredOptions()}
                options={followersOptions}
              />
              <Dropdown
                selection
                placeholder="Favourites List"
                options={favouriteData?.records?.map((record: any) => ({
                  key: record.id,
                  text: record.name,
                  value: record.id,
                }))}
                onChange={(event, data) =>
                  handleFilterChange("favouriteId", data.value)
                }
                value={filters.favouriteId}
                clearable
              />
              <CustomInput
                inputClassName="location-Input"
                name="location"
                id="location"
                placeholder="Enter location"
                onChange={handleFilterChange}
                initialValue={filters.location}
              />
              <Dropdown
                selection
                placeholder="Select Gender"
                options={NewGenderOptions.map((option) => ({
                  key: option.value,
                  text: option.label,
                  value: option.value,
                }))}
                value={filters.gender}
                onChange={(event, data) =>
                  handleFilterChange("gender", data.value)
                }
                clearable
              />
              <Dropdown
                selection
                placeholder="All Category"
                options={genreOptions.map(
                  (option: { id: any; label: any }) => ({
                    key: option.id,
                    text: option.label,
                    value: option.id,
                  })
                )}
                value={filters.geners}
                onChange={(event, data) =>
                  handleFilterChange("geners", data.value)
                }
                clearable
              />
              <CustomButton
                buttonText="Clear All"
                whiteBlueButton
                noOutline
                transparent
                textSmall
                handleClick={handleClearAllFilters}
              />
            </div>
          </div>
          <div className="sort-by flex width-100 padding-2 flex-align-center flex-justify-between margin-b-2">
            <span className="text-bold padding-r-1">Sort By: </span>
            <div className="width-60 flex flex-justify-between">
              {renderAlphabets()}
            </div>
            <div className="width-30 flex flex-justify-end">
              <CustomButton
                buttonText="Cancel"
                secondaryButton
                circular
                textSmall
                baseclassname={"margin-r-2"}
                handleClick={() => setSelectedUserIds([])}
              />
              <CustomButton
                primaryButton
                buttonText="Add Influencer"
                circular
                textSmall
                handleClick={handleAddInfluencerClick}
                disabled={!selectedUserIds.length}
              />
            </div>
          </div>
          {!fetching && !error && (
            <CustomTable
              tableData={records}
              columns={columns}
              tableError={error}
              tableMessage={error ? influencerMessage : "No Influencer Available"}
              sortTable={false}
              hasPagination
              has30DaysInfo
              currentPage={currentPage}
              fetching={fetching || false}
              paginationData={paginationInfo}
              handlePaginationChange={handlePagination}
              disablePagination={!data?.records?.length}
              contentRowClassName="padding-2"
              tableClassName="add-influencer-modal-table height-80"
            />
          )}
          {error && (
            <div className="padding-6 text-6 text-bold flex flex-align-center flex-justify-center">
              {influencerMessage}
            </div>
          )}
          {fetching && <Loading isGlobal />}
        </div>
      </Modal.Content>
      {showOverlay && (
        <FavouritesOverlay
          openOverlay={showOverlay}
          onCloseOverlay={handleCloseOverlay}
          influencerId={selectedUserIds}
        />
      )}
    </Modal>
  );
}

export default AddInfluencerModal;
