import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import moment from "moment";
import { getGenreOptionsState } from "store/selectors";
import { requestGenreOptions, updateUserProfile } from "store/rext";
import {
  addressTagOptions,
  findLabelById,
  formatDate,
  formatStatusString,
  getCity,
  getPostalCode,
  getState,
} from "utils/utils";
import CustomButton from "components/CustomButton";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import { TitleValue } from "./ProfilePage";
import { ProfileForm } from "./ProfileForm";
import { AddressType } from "utils/Enums";
import { groupAddressesByTag } from "./utils";

interface IProps {
  userData: any;
  isEditable: boolean | undefined;
  isEnable: boolean | undefined;
}

function PersonalInfo({ userData, isEditable, isEnable }: IProps) {
  const dispatch = useDispatch();
  const { data: genreOptions } = useSelector(getGenreOptionsState);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showNewAddressFields, setShowNewAddressFields] =
    useState<boolean>(false);
  const [selectedAddressId, setSelectedAddressId] = useState<string | null>(
    null
  );
  const [selectedTag, setSelectedTag] = useState<AddressType>(
    AddressType.OTHERS
  );

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
    reset,
    getValues,
  } = useForm({
    defaultValues: useMemo(() => {
      return userData;
    }, [userData]),
  });

  const handleOnSubmit = (data: any) => {
    const {
      city,
      state,
      gender,
      gener,
      address,
      reelprice,
      staticpostprice,
      storyprice,
      swipeupstoryprice,
      carouselpostprice,
    } = data || {};

    const transformData = {
      city: city,
      state: state,
      dob: moment(data.dob).format("YYYY-MM-DD"),
      gender,
      gener,
      reelprice: reelprice?.toString(),
      storyprice: storyprice?.toString(),
      staticpostprice: staticpostprice?.toString(),
      swipeupstoryprice: swipeupstoryprice?.toString(),
      carouselpostprice: carouselpostprice?.toString(),
    };

    const transformAddress: any = {
      addressLine: address?.addressLine,
      city: address?.city,
      postalCode: address?.postalCode,
      state: address?.state,
      address_tag: address?.addressTag,
    };
    if (selectedAddressId) {
      transformAddress.id = selectedAddressId;
    }
    dispatch(
      updateUserProfile({ profile: transformData, address: transformAddress })
    );
    setSelectedAddressId(null);
    setShowNewAddressFields(false);
  };

  const renderShipmentAddresses = () => {
    const handleRadioChange = (e: any) => {
      setSelectedTag(e.target.value as AddressType);
    };

    const filteredAddresses = userData.addresses?.filter(
      (item: any) => item.address_tag === selectedTag
    );
    return (
      <div className="flex flex-column flex-wrap width-100">
        {/* Render radio buttons for the address tags */}
        <div className="flex flex-row flex-wrap">
          {addressTagOptions.map((option) => (
            <div
              key={option.value}
              className="text-bold margin-b-2 flex flex-align-center"
            >
              <input
                type="radio"
                id={option.value}
                name="address_tag"
                value={option.value}
                checked={selectedTag === option.value}
                onChange={handleRadioChange}
              />
              <label htmlFor={option.value} className="margin-r-3 margin-l-1">
                {option.label}
              </label>
            </div>
          ))}
        </div>

        {/* Render filtered addresses */}
        {filteredAddresses?.length > 0 ? (
          filteredAddresses.map(
            (item: any) =>
              item.state &&
              item.city && (
                <div key={item.id} className="flex flex-align-center width-100">
                  <div className="text-3 text-grey-shade-2 width-80">
                    {item.address_line}
                  </div>
                  <CustomButton
                    iconProps={{
                      svgType: SVGType.CUSTOM,
                      name: CUSTOM_SVG_ICON.Edit,
                      size: "small",
                      baseclassname: "text-grey-shade-2",
                    }}
                    iconPosition={ICON_POSITION.RIGHT}
                    handleClick={() => handleEditAddress(item.id)}
                    greyButton
                    transparent
                    baseclassname="cursor-pointer width-20"
                    textSmall
                    circular
                  />
                </div>
              )
          )
        ) : (
          <div>No addresses found for this tag.</div>
        )}
      </div>
    );
  };

  const handleAddNewAddress = () => {
    setShowNewAddressFields(true);
    setSelectedAddressId(null);
  };

  const handleEditAddress = useCallback(
    (id: any) => {
      const selectedAddress = userData?.addresses?.find(
        (address: any) => address.id === id
      );
      setSelectedAddressId(id);
      setValue("address.addressTag", selectedAddress.address_tag || "");
      setValue("address.addressLine", selectedAddress.address_line || "");
      setValue("address.city", selectedAddress.city || "");
      setValue("address.state", selectedAddress.state || "");
      setValue("address.postalCode", selectedAddress.postal_code || "");
      setShowNewAddressFields(true);
    },
    [setValue, userData?.addresses]
  );

  const handleInputChange = (place: any) => {
    setValue("address.addressLine", place);
  };

  const handlePlaceSelected = (place: any) => {
    const selectedCity = getCity(place);
    const selectedState = getState(place);
    const selectedPostCode = getPostalCode(place);

    setValue("state", selectedState);
    setValue("city", selectedCity);
    setValue("postalCode", selectedPostCode);
  };

  const handleShipmentSelect = (place: any) => {
    const selectedCity = getCity(place);
    const selectedState = getState(place);
    const selectedPostCode = getPostalCode(place);

    setValue("address.city", selectedCity);
    setValue("address.state", selectedState);
    setValue("address.postalCode", selectedPostCode);
  };

  const handleEditButtonClick = () => {
    setEditMode(!editMode);
  };

  const groupedAddresses = groupAddressesByTag(userData?.addresses || []);

  const handlePermanentLocation = (value: string) => {};

  useEffect(() => {
    if (userData) {
      reset(userData);
      setValue("address", userData?.adresses);
      setValue("reelprice", userData.instaDetails?.reelprice);
      setValue("staticpostprice", userData.instaDetails?.staticpostprice);
      setValue("carouselpostprice", userData.instaDetails?.carouselpostprice);
      setValue("storyprice", userData.instaDetails?.storyprice);
      setValue("swipeupstoryprice", userData.instaDetails?.swipeupstoryprice);
    }
  }, [reset, userData]);

  useEffect(() => {
    dispatch(requestGenreOptions());
  }, []);

  console.log(getValues());

  return (
    <div className="flex flex-column width-100 padding-1 height-100 personal-container overflow-auto">
      <div className="flex flex-justify-between width-100 padding-2">
        <div className="profile-title text-grey-shade-2 margin-r-2 width-70">
          Detailed information about the user's profile, including personal
          details and preferences.
        </div>
        {isEditable && !editMode && (
          <div className="width-25">
            <CustomButton
              secondaryButton
              buttonText="Edit Profile"
              textSmall
              circular
              handleClick={handleEditButtonClick}
            />
          </div>
        )}
      </div>
      {!editMode && (
        <Fragment>
          <div className="flex flex-wrap flex-align-center width-100">
            {isEnable && (
              <TitleValue
                value={"Gender"}
                title={formatStatusString(userData.gender) || ""}
              />
            )}
            {isEnable && (
              <TitleValue
                value={"Date of Birth"}
                title={formatDate(userData.dob) || ""}
              />
            )}
            <TitleValue
              value={"Phone Number"}
              title={userData.phone || "-"}
              isValid={userData.instaDetails?.isvalidated}
            />
            <TitleValue value={"Email Address"} title={userData.email || "-"} />
          </div>
          {isEnable && userData?.gener?.length > 0 && (
            <div className="flex flex-column  width-95 text-bold margin-2">
              Genre
              <div className="flex flex-row flex-align-center width-100 flex-wrap ">
                {userData.gener?.map((item: string) => {
                  return (
                    <div className="text-align-center profile-gener text-3 text-grey-shade-4 border-1 border-grey-shade-1 padding-1 margin-r-2 margin-t-2">
                      {findLabelById(genreOptions, item)}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {isEnable && (
            <TitleValue
              value={"Permanant Address"}
              title={
                userData.city && userData.state
                  ? `${userData.city} , ${userData.state}`
                  : "-"
              }
            />
          )}
          {isEnable && userData?.addresses?.length > 0 && (
            <Fragment>
              <span className="text-4 text-bold margin-2">
                Shipment Addresses
              </span>
              {Object.keys(groupedAddresses).map((tag) => (
        <div key={tag} className="flex flex-column flex-wrap width-95 margin-l-2">
          {/* Display the address_tag as a group header */}
          <span className="text-3 text-bold margin-t-3">{tag}</span>

          {groupedAddresses[tag].map((item: any, index: number) => (
            item.state && item.city && (
              <div key={index} className="profile-title text-grey-shade-2">
                <span className="margin-r-2">{index + 1}.</span>
                {`${item.address_line}, ${item.city}, ${item.state} ${item.postal_code || ""}`}
              </div>
            )
          ))}
        </div>
      ))}
            </Fragment>
          )}
        </Fragment>
      )}
      {editMode && isEditable && (
        <ProfileForm
          isSubmitting={isSubmitting}
          control={control}
          errors={errors}
          genreOptions={genreOptions}
          renderShipmentAddresses={renderShipmentAddresses}
          handleAddNewAddress={handleAddNewAddress}
          handleCancelClick={() => setEditMode(false)}
          handleInputChange={handleInputChange}
          handlePermanentLocation={handlePermanentLocation}
          handlePlaceSelected={handlePlaceSelected}
          handleShipmentSelect={handleShipmentSelect}
          handleSubmitForm={handleSubmit(handleOnSubmit)}
          showNewAddressFields={showNewAddressFields}
        />
      )}
    </div>
  );
}

export default PersonalInfo;
