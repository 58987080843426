import PlusSVG from "./Icons/Plus";
import ArrowDownSVG from "./Icons/ArrowDown";
import DeleteSVG from "./Icons/Delete";
import EditSVG from "./Icons/Edit";
import CaretUpSVG from "./Icons/CaretUp";
import CaretDownSVG from "./Icons/CaretDown";
import CrossSVG from "./Icons/Cross";
import EyeSVG from "./Icons/EyeIcon";
import Hamburger from "./Icons/Hamburger";
import NewEdit from "./Icons/NewEdit";
import DownloadSVG from "./Icons/Download";
import FacebookSVG from "./Icons/Facebook";
import GoogleSVG from "./Icons/Google";
import PasswordSVG from "./Icons/Password";
import CalendarSVG from "./Icons/Calendar";
import HelpSVG from "./Icons/Help";
import LogoutSVG from "./Icons/Logout";
import LikesSVG from "./Icons/Likes";
import CommentSVG from "./Icons/Comments";
import ViewsSVG from "./Icons/Views";
import EmailSVG from "./Icons/Email";
import DashboardSVG from "./Icons/Dashboard";
import CampaignsSVG from "./Icons/Campaigns";
import ProfileSVG from "./Icons/Profile";
import CostSVG from "./Icons/Cost";
import Like1SVG from "./Icons/Like1";
import Comment1SVG from "./Icons/Comment1";
import BudgetSVG from "./Icons/Budget";
import SortingSVG from "./Icons/Sorting";
import CalendarSmallSVG from "./Icons/CalendarSmall";
import FilterSVG from "./Icons/Filter";
import PaymentTermSVG from "./Icons/PaymentTerm";
import PhoneSVG from "./Icons/Phone";
import MessagesSVG from "./Icons/Messages";
import SettingsSVG from "./Icons/Settings";
import SearchSVG from "./Icons/Search";
import BellSVG from "./Icons/Bell";
import UserSVG from "./Icons/User";
import ClipBoardSVG from "./Icons/ClipBoard";
import ApproveLikeSVG from "./Icons/ApproveLike";
import AcceptIconSVG from "./Icons/AcceptIcon";
import RejectIconSVG from "./Icons/RejectIcon";
import ChatSVG from "./Icons/Chat";
import Brand1SVG from "./Icons/Brand1";
import BrandUserSVG from "./Icons/BrandUser";
import UploadSVG from "./Icons/Upload";
import VerifiedTickSVG from "./Icons/VerifiedTick";
import AddFavSVG from "./Icons/AddFav";
import HideEyeSVG from "./Icons/HideEye";
import ManageSVG from "./Icons/Manage";
import EllipseSVG from "./Icons/Ellipse";
import LeftArrowSVG from "./Icons/LeftArrow";
import FileSVG from "./Icons/File";
import ArrowLeftSVG from "./Icons/ArrowLeft";
import MailSVG from "./Icons/Mail";
import InstaIconSVG from "./Icons/InstaIcon";
import InfoSVG from "./Icons/Info";
import FAQSVG from "./Icons/FAQ";
import RefreshSVG from "./Icons/Refresh";

export enum CUSTOM_SVG_ICON {
  Plus = "PlusSVG",
  ArrowDown = "ArrowDownSVG",
  Delete = "DeleteSVG",
  Edit = "EditSVG",
  CaretUp = "CaretUpSVG",
  CaretDown = "CaretDownSVG",
  Cross = "CrossSVG",
  EyeSVG = "EyeSVG",
  Hamburger = "Hamburger",
  NewEdit = "NewEdit",
  Download = "DownloadSVG",
  facebook = "FacebookSVG",
  Google = "GoogleSVG",
  Password = "PasswordSVG",
  Mail = "MailSVG",
  Calendar = "CalendarSVG",
  Help = "HelpSVG",
  Logout = "LogoutSVG",
  Dashboard = "DashboardSVG",
  Profile = "ProfileSVG",
  Campaigns = "CampaignsSVG",
  Cost = "CostSVG",
  Likes = "LikesSVG",
  Comment = "CommentSVG",
  Views = "ViewsSVG",
  Email = "EmailSVG",
  Like1 = "Like1SVG",
  Comment1 = "Comment1SVG",
  Budget = "BudgetSVG",
  Filter = "FilterSVG",
  PaymentTerm = "PaymentTermSVG",
  Sorting = "SortingSVG",
  CalendarSmall = "CalendarSmallSVG",
  Phone = "PhoneSVG",
  Messages = "MessagesSVG",
  Settings = "SettingsSVG",
  Bell = "BellSVG",
  Search = "SearchSVG",
  User = "UserSVG",
  ClipBoard = "ClipBoardSVG",
  ApproveLike = "ApproveLikeSVG",
  AcceptIcon = "AcceptIconSVG",
  RejectIcon = "RejectIconSVG",
  Chat = "ChatSVG",
  Brand1 = "Brand1SVG",
  BrandUser = "BrandUserSVG",
  Upload = "UploadSVG",
  VerifiedTick = "VerifiedTickSVG", 
  AddFav = "AddFavSVG",
  HideEye = "HideEyeSVG",
  Manage = "ManageSVG", 
  Ellipse = "EllipseSVG",
  LeftArrow = "LeftArrowSVG", 
  File = "FileSVG", 
  ArrowLeft = "ArrowLeftSVG",
  InstaIcon = "InstaIconSVG",
  Info = "InfoSVG", 
  FAQ = "FAQSVG",
  Refresh = "RefreshSVG"
}

export const CustomIconRef: any = {
  [CUSTOM_SVG_ICON.Plus]: PlusSVG,
  [CUSTOM_SVG_ICON.ArrowDown]: ArrowDownSVG,
  [CUSTOM_SVG_ICON.Delete]: DeleteSVG,
  [CUSTOM_SVG_ICON.Edit]: EditSVG,
  [CUSTOM_SVG_ICON.CaretUp]: CaretUpSVG,
  [CUSTOM_SVG_ICON.CaretDown]: CaretDownSVG,
  [CUSTOM_SVG_ICON.Cross]: CrossSVG,
  [CUSTOM_SVG_ICON.EyeSVG]: EyeSVG,
  [CUSTOM_SVG_ICON.Hamburger]: Hamburger,
  [CUSTOM_SVG_ICON.NewEdit]: NewEdit,
  [CUSTOM_SVG_ICON.Download]: DownloadSVG,
  [CUSTOM_SVG_ICON.facebook]: FacebookSVG,
  [CUSTOM_SVG_ICON.Google]: GoogleSVG,
  [CUSTOM_SVG_ICON.Password]: PasswordSVG,
  [CUSTOM_SVG_ICON.Mail]: MailSVG,
  [CUSTOM_SVG_ICON.Calendar]: CalendarSVG,
  [CUSTOM_SVG_ICON.Help]: HelpSVG,
  [CUSTOM_SVG_ICON.Logout]: LogoutSVG,
  [CUSTOM_SVG_ICON.Likes]: LikesSVG,
  [CUSTOM_SVG_ICON.Comment]: CommentSVG,
  [CUSTOM_SVG_ICON.Views]: ViewsSVG,
  [CUSTOM_SVG_ICON.Email]: EmailSVG,
  [CUSTOM_SVG_ICON.Campaigns]: CampaignsSVG,
  [CUSTOM_SVG_ICON.Dashboard]: DashboardSVG,
  [CUSTOM_SVG_ICON.Profile]: ProfileSVG,
  [CUSTOM_SVG_ICON.Cost]: CostSVG,
  [CUSTOM_SVG_ICON.Messages]: MessagesSVG,
  [CUSTOM_SVG_ICON.Settings]: SettingsSVG,
  [CUSTOM_SVG_ICON.Like1]: Like1SVG,
  [CUSTOM_SVG_ICON.Comment1]: Comment1SVG,
  [CUSTOM_SVG_ICON.Budget]: BudgetSVG,
  [CUSTOM_SVG_ICON.CalendarSmall]: CalendarSmallSVG,
  [CUSTOM_SVG_ICON.Filter]: FilterSVG,
  [CUSTOM_SVG_ICON.Sorting]: SortingSVG,
  [CUSTOM_SVG_ICON.PaymentTerm]: PaymentTermSVG,
  [CUSTOM_SVG_ICON.Bell]: BellSVG,
  [CUSTOM_SVG_ICON.Search]: SearchSVG,
  [CUSTOM_SVG_ICON.User]: UserSVG,
  [CUSTOM_SVG_ICON.ClipBoard]: ClipBoardSVG,
  [CUSTOM_SVG_ICON.ApproveLike]: ApproveLikeSVG,
  [CUSTOM_SVG_ICON.Phone]: PhoneSVG,
  [CUSTOM_SVG_ICON.AcceptIcon]: AcceptIconSVG ,
  [CUSTOM_SVG_ICON.RejectIcon]: RejectIconSVG,
  [CUSTOM_SVG_ICON.Chat]: ChatSVG,
  [CUSTOM_SVG_ICON.Brand1]: Brand1SVG,
  [CUSTOM_SVG_ICON.BrandUser] : BrandUserSVG,
  [CUSTOM_SVG_ICON.Upload] : UploadSVG,
  [CUSTOM_SVG_ICON.VerifiedTick] : VerifiedTickSVG,
  [CUSTOM_SVG_ICON.AddFav] : AddFavSVG,
  [CUSTOM_SVG_ICON.HideEye] : HideEyeSVG , 
  [CUSTOM_SVG_ICON.Manage] : ManageSVG , 
  [CUSTOM_SVG_ICON.Ellipse] : EllipseSVG, 
  [CUSTOM_SVG_ICON.LeftArrow] : LeftArrowSVG,
  [CUSTOM_SVG_ICON.File] : FileSVG,
  [CUSTOM_SVG_ICON.ArrowLeft] : ArrowLeftSVG,
  [CUSTOM_SVG_ICON.InstaIcon] : InstaIconSVG,
  [CUSTOM_SVG_ICON.Info] : InfoSVG,
  [CUSTOM_SVG_ICON.FAQ] : FAQSVG,
  [CUSTOM_SVG_ICON.Refresh] : RefreshSVG
};
