import { URLRoutes } from "URLRoutes";
import {
  adminInviteRext,
  createUserByRegistration,
  forgetPasswordRext,
  campaignsListRext,
  getDetailedProfileListRext,
  getGenreOptions,
  influencerDetailListRext,
  mobileVerifyRext,
  changePasswordRext,
  signUpByEmailRext,
  verifyOTPRext,
  bulkInviteUsersRext,
  campaignFormRext,
  userDetailRext,
  brandListRext,
  brandFormRext,
  brandUserListRext,
  brandUsersFormRext,
  campaignInfluencerDetailRext,
  allInfluencersListRext,
  postInfluencerRext,
  syncInstaMatrixRext,
  billingCampaignInfluencerRext,
  uploadGuideLinesRext,
  uploadVideoRext,
  videoListRext,
  favouritesRext,
  favouritesListRext,
  notificationListRext,
  notificationRext,
  chatNotificationListRext,
  chatNotificationRext,
  instaMatrixRext,
} from "./rext";

export function requestMobileVerification(body: any, urlParams: any) {
  return mobileVerifyRext.request({
    method: "post",
    url: URLRoutes.server.userVerifyPhone,
    body,
    urlParams: urlParams,
  });
}

export function requestAdminInvite(body: any) {
  return adminInviteRext.request({
    method: "post",
    url: URLRoutes.server.inviteUser,
    body,
  });
}

export function requestReInvite(urlParams: any) {
  return adminInviteRext.update({
    method: "put",
    url: URLRoutes.server.reInvite,
    urlParams: urlParams,
  });
}
export function sendInviteUser(urlParams: any) {
  return adminInviteRext.update({
    method: "put",
    url: URLRoutes.server.sendInviteToUser,
    urlParams: urlParams,
  });
}

export function getInvitedUserDetails(urlParams: any) {
  return userDetailRext.request({
    method: "get",
    url: URLRoutes.server.userDetail,
    urlParams: urlParams,
  });
}

export function requestOTPVerification(body: any, urlParams: any) {
  return verifyOTPRext.request({
    method: "post",
    url: URLRoutes.server.verifyOtp,
    body,
    urlParams: urlParams,
  });
}

export function resendOTPVerify(body: any, urlParams: any) {
  return getGenreOptions.request({
    method: "post",
    url: URLRoutes.server.resendOTP,
    body,
    urlParams: urlParams,
  });
}

export function requestForgetPassword(body: any) {
  return forgetPasswordRext.request({
    method: "post",
    url: URLRoutes.server.userForgotPassword,
    body,
  });
}

export function requestResetPassword(body: any) {
  return changePasswordRext.request({
    method: "post",
    url: URLRoutes.server.changePassword,
    body,
  });
}

export function requestInfluencerUsersList(urlParams: any, queryParams: any) {
  return influencerDetailListRext.request({
    method: "get",
    url: URLRoutes.server.userList,
    urlParams: urlParams,
    queryParams: queryParams,
  });
}

export function requestGenreOptions() {
  return getGenreOptions.request({
    method: "get",
    url: URLRoutes.server.geners,
  });
}

export function requestSignUpByEmail(body: any) {
  return signUpByEmailRext.request({
    method: "post",
    url: URLRoutes.server.registerByEmail,
    body,
  });
}

export function requestCreateUser(body: any, urlParams: any) {
  return createUserByRegistration.request({
    method: "post",
    url: URLRoutes.server.userCreate,
    body,
    urlParams: urlParams,
  });
}

export function requestUpdateUser(body: any, urlParams: any) {
  return createUserByRegistration.update({
    method: "put",
    url: URLRoutes.server.userUpdate,
    body,
    urlParams: urlParams,
  });
}

export function requestBulkInviteUsers(body: any) {
  return bulkInviteUsersRext.request({
    method: "post",
    url: URLRoutes.server.userUpload,
    body: body,
  });
}

//Campaign API Actions
export function requestCreateCampaign(body: any) {
  return campaignFormRext.request({
    method: "post",
    url: URLRoutes.server.createCampaign,
    body,
  });
}

export function requestCampaignDetail(urlParams: any, urlRoute: string) {
  return campaignFormRext.request({
    method: "get",
    url: urlRoute,
    urlParams: urlParams,
  });
}

export function updateCampaign(body: any, urlParams: any) {
  return campaignFormRext.update({
    method: "put",
    url: URLRoutes.server.updateCamapign,
    body,
    urlParams: urlParams,
  });
}

export function requestCampaignsList(urlRoute: string, body: any) {
  return campaignsListRext.request({
    method: "post",
    url: urlRoute,
    body,
  });
}

//Brand API Actions
export function requestBrandsList(body: any) {
  return brandListRext.request({
    method: "post",
    url: URLRoutes.server.brandsList,
    body,
  });
}

export function requestBrandDetail(urlParams: any) {
  return brandFormRext.request({
    method: "get",
    url: URLRoutes.server.brandDetail,
    urlParams: urlParams,
  });
}

export function requestCreateBrand(body: any) {
  return brandFormRext.request({
    method: "post",
    url: URLRoutes.server.createBrand,
    body,
  });
}

export function requestUpdateBrand(body: any, urlParams: any) {
  return brandFormRext.update({
    method: "put",
    url: URLRoutes.server.updateBrand,
    body,
    urlParams: urlParams,
  });
}

export function deleteBrand(urlParams: any) {
  return brandFormRext.request({
    method: "delete",
    url: URLRoutes.server.deleteBrand,
    urlParams: urlParams,
  });
}

export function requestBrandUsersList(body: any) {
  return brandUserListRext.request({
    method: "post",
    url: URLRoutes.server.brandUsersList,
    body,
  });
}

export function requestBrandUserDetail(urlParams: any) {
  return brandUsersFormRext.request({
    method: "get",
    url: URLRoutes.server.brandUserDetail,
    urlParams: urlParams,
  });
}

export function requestCreateBrandUser(body: any) {
  return brandUsersFormRext.request({
    method: "post",
    url: URLRoutes.server.createBrandUser,
    body,
  });
}

export function requestUpdateBrandUser(body: any, urlParams: any) {
  return brandUsersFormRext.update({
    method: "put",
    url: URLRoutes.server.updateBrandUser,
    body,
    urlParams: urlParams,
  });
}

export function deleteBrandUser(urlParams: any) {
  return brandUsersFormRext.request({
    method: "delete",
    url: URLRoutes.server.deleteBrandUser,
    urlParams: urlParams,
  });
}

//INFluencer Action
export function requestCampaignInfluencerList(body: any) {
  return campaignInfluencerDetailRext.request({
    method: "post",
    url: URLRoutes.server.influencerList,
    body,
  });
}

export function requestAllCampaignInfluencers(body: any, queryParams?: any) {
  return allInfluencersListRext.request({
    method: "post",
    url: URLRoutes.server.getInfluencers,
    body,
    queryParams: queryParams,
  });
}

export function requestAllInfluencers(body: any, queryParams?: any) {
  return allInfluencersListRext.request({
    method: "post",
    url: URLRoutes.server.getAllInfluencersList,
    body,
    queryParams: queryParams,
  });
}

export function postInfluencersToCampaign(body: any) {
  return postInfluencerRext.request({
    method: "post",
    url: URLRoutes.server.influencerPost,
    body,
  });
}

export function requestSyncInstaMatrix(urlParams: any) {
  return syncInstaMatrixRext.request({
    method: "get",
    url: URLRoutes.server.getInstaMatrix,
    urlParams: urlParams,
  });
}

export function requestSyncInstaMedia(body: any, urlParams: any) {
  return syncInstaMatrixRext.request({
    method: "post",
    url: URLRoutes.server.syncInstaData,
    body,
    urlParams: urlParams,
  });
}

export function updateBillingDetails(body: any, urlParams: any) {
  return billingCampaignInfluencerRext.request({
    method: "post",
    url: URLRoutes.server.updatePrice,
    body,
    urlParams: urlParams,
  });
}

export function applyForCampaign(url: string,  body: any, urlParams: any) {
  return billingCampaignInfluencerRext.update({
    method: "put",
    url: url,
    body,
    urlParams: urlParams,
  });
}

export function requestALLBrandUsersList(body: any) {
  return brandUserListRext.request({
    method: "post",
    url: URLRoutes.server.allBrandUsersList,
    body,
  });
}

export function postToInfluencers(body: any) {
  return postInfluencerRext.request({
    method: "post",
    url: URLRoutes.server.postToInfluencer,
    body,
  });
}

export function postToBrands(body: any) {
  return postInfluencerRext.request({
    method: "post",
    url: URLRoutes.server.postToBrand,
    body,
  });
}

export function uploadGuidelinesToCampaign(body: FormData) {
  return uploadGuideLinesRext.request({
    method: "post",
    url: URLRoutes.server.uploadGuideLines,
    body,
  });
}

export function requestGuidelineDetail(urlParams: any) {
  return uploadGuideLinesRext.request({
    method: "get",
    url: URLRoutes.server.guidelineDetail,
    urlParams,
  });
}

export function deleteGuideline(urlParams: any) {
  return uploadGuideLinesRext.request({
    method: "delete",
    url: URLRoutes.server.guidelineDetail,
    urlParams,
  });
}

export function uploadVideoToHOB(body: FormData) {
  return uploadVideoRext.request({
    method: "post",
    url: URLRoutes.server.uploadVideo,
    body,
  });
}

export function requestVideoDetail(urlParams: any) {
  return uploadVideoRext.request({
    method: "get",
    url: URLRoutes.server.getVideoDetails,
    urlParams,
  });
}

export function deleteUploadedVideo(urlParams: any) {
  return uploadVideoRext.request({
    method: "delete",
    url: URLRoutes.server.deleteVideo,
    urlParams,
  });
}

export function updateVideoStatus(body: any, urlParams: any) {
  return uploadVideoRext.update({
    method: "put",
    url: URLRoutes.server.updateVideoStatus,
    body,
    urlParams,
  });
}

export function getVideoDetail(urlParams: any) {
  return uploadVideoRext.request({
    method: "get",
    url: URLRoutes.server.getVideo,
    urlParams,
  });
}

export function getVideoList(urlParams: any) {
  return videoListRext.request({
    method: "get",
    url: URLRoutes.server.videoList,
    urlParams,
  });
}

export function requestFavouritesList(body: any) {
  return favouritesListRext.request({
    method: "post",
    url: URLRoutes.server.favouritesList,
    body,
  });
}

export function requestMarkFavourite(body: any) {
  return favouritesRext.request({
    method: "post",
    url: URLRoutes.server.markFavourite,
    body,
  });
}

export function requestCreateFavouriteCategory(body: any) {
  return favouritesRext.request({
    method: "post",
    url: URLRoutes.server.createFavourite,
    body,
  });
}

export function changeShipmentStatus(body: any, urlParams: any) {
  return postInfluencerRext.update({
    method: "put",
    url: URLRoutes.server.updateShipmentStatus,
    body,
    urlParams,
  });
}

export function requestUserProfile(urlParams: any) {
  return userDetailRext.request({
    method: "get",
    url: URLRoutes.server.userProfile,
    urlParams,
  });
}

export function updateUserProfile(body: any) {
  return userDetailRext.update({
    method: "put",
    url: URLRoutes.server.upadteProfile,
    body,
  });
}

export function updateFCMToken(body: any) {
  return userDetailRext.request({
    method: "post",
    url: URLRoutes.server.userFcmUpdate,
    body,
  });
}

export function requestNotificationList(body: any) {
  return notificationListRext.request({
    method: "post",
    url: URLRoutes.server.notificationList,
    body,
  });
}

export function getNotificationCount() {
  return notificationRext.request({
    method: "get",
    url: URLRoutes.server.unreadCount,
  });
}

export function updateReadNotification(urlParams: any) {
  return notificationRext.update({
    method: "put",
    url: URLRoutes.server.markReadNotification,
    urlParams,
  });
}

export function updateAllReadNotification() {
  return notificationRext.update({
    method: "put",
    url: URLRoutes.server.markAllReadNotification,
  });
}

export function sendInviteToMultipleUsers(body: any) {
  return bulkInviteUsersRext.update({
    method: "post",
    url: URLRoutes.server.sendMultipleInvites,
    body,
  });
}
export function chatNotifications(body: any) {
  return userDetailRext.request({
    method: "post",
    url: URLRoutes.server.chatNotifications,
    body,
  });
}
export function chatNotificationsRead(body: any) {
  return chatNotificationRext.request({
    method: "post",
    url: URLRoutes.server.chatNotificationsRead,
    body,
  });
}
export function requestChatNotificationsList(body: any) {
  return chatNotificationListRext.request({
    method: "post",
    url: URLRoutes.server.chatNotificationList,
    body,
  });
}
export function getChatNotificationCount() {
  return chatNotificationRext.request({
    method: "get",
    url: URLRoutes.server.chatUnreadCount,
  });
}
export function updateReadChatNotification(urlParams: any) {
  return chatNotificationRext.update({
    method: "put",
    url: URLRoutes.server.chatMarkReadNotification,
    urlParams,
  });
}

export function updateAllReadChatNotification() {
  return chatNotificationRext.update({
    method: "put",
    url: URLRoutes.server.chatMarkAllReadNotification,
  });
}

export function requestAgeMatrixDetails(body: any, urlParams: any) {
  return instaMatrixRext.request({
    method: "post",
    url: URLRoutes.server.ageMatrix,
    body,
    urlParams: urlParams,
  })
}

export function requestLocationMatrixDetails(body: any, urlParams: any) {
  return instaMatrixRext.request({
    method: "post" , 
    url: URLRoutes.server.locationMatrix , 
    body,
    urlParams: urlParams,
  })
}

export function requestEngagementMatrixDetails(body: any, urlParams: any) {
  return instaMatrixRext.request({
    method: "post" , 
    url: URLRoutes.server.engageMatrix , 
    body,
    urlParams: urlParams,
  })
}

export function requestInstaMatrixDetails(body: any, urlParams: any) {
  return signUpByEmailRext.request({
    method: "post" , 
    url: URLRoutes.server.instaMatrixDetails , 
    body,
    urlParams: urlParams,
  })
}


export function deleteUser(body: any) {
  return mobileVerifyRext.request({
    method: "post",
    url: URLRoutes.server.deleteUser,
    body,
  });
}
