import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomButton from "components/CustomButton";
import {
  HookFormInput,
  HookFormSelect,
  HookFormAddressAutoComplete,
  HookFormDatePicker,
} from "components/FormInputs";
import { hookformRequired } from "utils/FormValidations";
import { SVGType } from "components/SvgIcon";
import { fetchRequest, getFullUrl } from "Api";
import {
  getCity,
  getState,
  NewGenderOptions,
  transformOptionsToKeyValue,
} from "utils/utils";
import { getBaseUrl } from "store/selectors";
import "./style.scss";

const requiredValidation = {
  ...hookformRequired("Required"),
};

function Form1({
  control,
  errors,
  handleNext,
  genreOptions,
  watch,
  setValue,
  userId,
}: any) {
  const [isInstaHandleVerified, setIsInstaHandleVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const instaField = watch("instahandle");
  const baseUrl = useSelector(getBaseUrl);


  const handleInstaHandleBlur = async () => {
    try {
      if (instaField) {
        setLoading(true);
        const canUseInstaID = await checkInstaId(instaField);
        if (canUseInstaID) {
          setIsInstaHandleVerified(true);
        } else {
          setIsInstaHandleVerified(false);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Invalid Insta Id");
      setIsInstaHandleVerified(false);
    }
  };
  const checkInstaId = async (instaField: any) => {
    try {
      const response = await fetchRequest(
        getFullUrl(baseUrl, "/user/instaIdCheck"),
        undefined!,
        "post",
        { instaID: instaField, userId: userId }
      );
      if (!response.canUseInstaID) {
        toast.warning(response.message);
      }
      return response.canUseInstaID;
    } catch (error) {
      console.error("Error checking Insta ID:", error);
      toast.warning("Invalid Insta Id.");
      return false;
    }
  };

 const validateDob = {
    validate: (dob: string) => {
      const today = new Date();
      const birthDate = new Date(dob);
      const age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      const dayDiff = today.getDate() - birthDate.getDate();
  
      // Check if the date is valid
      if (dob && isNaN(birthDate.getTime())) {
        return "Invalid date format";
      }
  
      // Check age criteria
      if (age < 13 || (age === 13 && (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)))) {
        return "Your age must be 13 years or older"; // Invalid
      }
      return undefined; // Valid
    },
  };
  
  

  const handleNextClick = async () => {
    if (handleNext && isInstaHandleVerified) {
      handleNext(); 
    }
  };

  const handlePlaceSelected = (place: any) => {
    updateStateAndCity(place);
  };

  const updateStateAndCity = (place: any) => {
    const selectedCity = getCity(place);
    const selectedState = getState(place);

    setValue("state", selectedState);
    setValue("city", selectedCity);
  };

  return (
    <Fragment>
      <div className="input-label text-align-center">Let’s Get You Started</div>
      <HookFormInput
        validation={requiredValidation}
        control={control}
        errors={errors}
        label="Name"
        id="name"
        name="name"
        placeholder="Enter your name here..."
        required
      />
      <HookFormSelect
        validation={requiredValidation}
        options={transformOptionsToKeyValue(genreOptions)}
        control={control}
        errors={errors}
        label="Genre"
        id="gener"
        name="gener"
        placeholder="Select the genre(s)..."
        multiSelect
        baseClassName="registration-geners"
        required
      />
      <div className="flex flex-row flex-justify-between width-100">
        <HookFormSelect
          validation={requiredValidation}
          control={control}
          errors={errors}
          id="gender"
          name="gender"
          label="Gender"
          baseClassName="width-45"
          options={NewGenderOptions}
          inputClassName="width-100"
          placeholder={"Select"}
          required
        />
        <HookFormDatePicker
          control={control}
          errors={errors}
          id="dob"
          name="dob"
          label="Date of Birth"
          baseClassName="width-45"
          placeholder="Select Date"
          validation={validateDob}
          // maxDate={new Date()}
        />
      </div>
      <HookFormAddressAutoComplete
        name="location"
        id="location"
        onSelect={handlePlaceSelected}
        control={control}
        errors={errors}
        label="Address"
      />

      <div className="flex flex-row flex-justify-between width-100">
        <HookFormInput
          control={control}
          errors={errors}
          id="city"
          name="city"
          placeholder="City"
          baseClassName="width-45"
        />
        <HookFormInput
          control={control}
          errors={errors}
          id="state"
          name="state"
          placeholder="State"
          baseClassName="width-45"
        />
      </div>

      <HookFormInput
        control={control}
        errors={errors}
        label="Instagram ID"
        id="instaHandle"
        name="instahandle"
        validation={requiredValidation}
        placeholder="Enter Instagram handle here..."
        onBlur={handleInstaHandleBlur}
        iconProps={{
          name: loading ? "spinner" : isInstaHandleVerified ? "check" : "close",
          svgType: SVGType.SEMANTIC,
          size: "small",
        }}
        iconClassName={loading ? " insta-verify-icon text-default-color" :
          isInstaHandleVerified
            ? "text-green-shade-2 insta-verify-icon"
            : "text-danger-color insta-verify-icon"
        }
        required
      />
      <HookFormInput
        control={control}
        errors={errors}
        label="YouTube Channel"
        id="youtubechannel"
        name="youtubechannel"
        placeholder="Enter YouTube Channel here..."
      />
      <CustomButton
        // type={"submit"}
        buttonText="NEXT"
        handleClick={handleNextClick}
        primaryButton
        round
        baseclassname={"width-100"}
        disabled={!isInstaHandleVerified}
      />
    </Fragment>
  );
}

export default Form1;
